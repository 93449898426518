import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { baseUrl } from '../../utils/url-config';
import { User } from "../../redux/users";
import { getUserInfo } from "../../utils/authentication";
import { axiosInstance } from '../../utils/axios-instance';


export interface ICProject {
    [x: string]: any;
    ID: number;
    ICPROJECTTYPEID: number;
    OWNERID: number;
    NAME: string;
    DESCRIPTION: string;
    DETAILINSTRUCTION: string;
    PRODUCTID: number;
    REVIEWCYCLE: string;
    STARTDATE: Date;
    ENDDATE: Date;
    EARNINGTYPE: string;
    EARNINGVALUE: number;
    AVAILABLE: boolean;
    CREATEDBY: string;
    UPDATEDBY: string;
}

export interface ICProjectState {
    allICProjects: ICProject[]; //TODO rename this field to allICProjectsByOwner
    allAvailableICProjects: ICProject[];
    currentICProject: any;
    loading: boolean;
    error: string | null;
}

interface updateProjectPayload {
    ownerId: number;
    projectId: number;
    availability: boolean
}

const initialState: ICProjectState = {
    allICProjects: [],
    allAvailableICProjects: [],
    currentICProject: {},
    loading: false,
    error: null
};

/**
 * Return all the IC Projects for the current user
 */
export const fetchAllICProjects = createAsyncThunk<ICProject[], void, { rejectValue: Error }>(
    'store/fetchAllICProjects/get',
    async (_, thunkAPI) => {
        try {
            const userInfo: User = getUserInfo();
            const response = await axiosInstance.get<ICProject[]>(`${baseUrl}/ic-projects?ownerId=${userInfo.ID}`);
            return response.data;
        } catch (error: unknown) {
            return thunkAPI.rejectWithValue(error as Error);
        }
    }
);

/**
 * Return all available IC Projects for field agent to apply
 */
export const fetchAllAvailableICProjects = createAsyncThunk<ICProject[], void, { rejectValue: Error }>(
    'store/fetchAllAvailableICProjects/get',
    async (_, thunkAPI) => {
        try {
            const response = await axiosInstance.get<ICProject[]>(`${baseUrl}/ic-projects/available/true`);
            return response.data;
        } catch (error: unknown) {
            return thunkAPI.rejectWithValue(error as Error);
        }
    }
);

/**
 * Return the store by store id
 */
export const fetchICProjectInfo = createAsyncThunk<ICProject, string, { rejectValue: Error }>(
    'store/fetchICProjectInfo/get',
    async (id, thunkAPI) => {
        try {
            const response = await axiosInstance.get<ICProject>(`${baseUrl}/ic-projects/${id}`);
            return response.data;
        } catch (error: unknown) {
            return thunkAPI.rejectWithValue(error as Error);
        }
    }
);

export const updateICProjectInfo = createAsyncThunk<ICProject, updateProjectPayload, { rejectValue: Error }>(
    'store/updateICProjectInfo/put',
    async (payload, thunkAPI) => {
        try {
            const response = await axiosInstance.put<ICProject>(`${baseUrl}/ic-projects/${payload.projectId}`, payload);
            return response.data;
        } catch (error: unknown) {
            return thunkAPI.rejectWithValue(error as Error);
        }
    }
);

export const createIcProject = createAsyncThunk<ICProject, any, { rejectValue: Error }>(
    'store/createIcProject/post',
    async (payload, thunkAPI) => {
        try {
            const response = await axiosInstance.post<ICProject>(`${baseUrl}/ic-projects/create`, payload);
            return response.data;
        } catch (error: unknown) {
            return thunkAPI.rejectWithValue(error as Error);
        }
    }
);

export const updateIcProject = createAsyncThunk<ICProject, any, { rejectValue: Error }>(
    'store/updateIcProject/put',
    async (payload, thunkAPI) => {
        try {
            const response = await axiosInstance.put<ICProject>(`${baseUrl}/ic-projects/update/${payload.icProjectId}`, payload);
            return response.data;
        } catch (error: unknown) {
            return thunkAPI.rejectWithValue(error as Error);
        }
    }
);

const icProjectsSlice = createSlice({
    name: 'icprojects',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllICProjects.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchAllICProjects.fulfilled, (state, action: PayloadAction<ICProject[]>) => {
                state.loading = false;
                state.allICProjects = action.payload;
            })
            .addCase(fetchAllICProjects.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload;
            }).addCase(fetchAllAvailableICProjects.pending, (state) => {
                state.loading = true;
            }).addCase(fetchAllAvailableICProjects.fulfilled, (state, action: PayloadAction<ICProject[]>) => {
                state.loading = false;
                state.allAvailableICProjects = action.payload;
            }).addCase(fetchAllAvailableICProjects.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload;
            }).addCase(fetchICProjectInfo.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchICProjectInfo.fulfilled, (state, action: PayloadAction<ICProject>) => {
                state.loading = false;
                state.currentICProject = action.payload;
            })
            .addCase(fetchICProjectInfo.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default icProjectsSlice.reducer;
