import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { baseUrl } from '../../utils/url-config';
import { axiosInstance } from '../../utils/axios-instance';

const initialState = {
};


export const createStoreIcProject = createAsyncThunk<any, any, { rejectValue: Error }>(
    'store/createStoreIcProject/post',
    async (payload, thunkAPI) => {
        try {
            const response = await axiosInstance.post<any>(`${baseUrl}/store-ic-projects/create`, payload);
            return response.data;
        } catch (error: unknown) {
            return thunkAPI.rejectWithValue(error as Error);
        }
    }
);

export const updateStoreIcProject = createAsyncThunk<any, any, { rejectValue: Error }>(
    'store/updateStoreIcProject/put',
    async (payload, thunkAPI) => {
        try {
            const response = await axiosInstance.put<any>(`${baseUrl}/store-ic-projects/update/${payload.storeICProjectId}`, payload);
            return response.data;
        } catch (error: unknown) {
            return thunkAPI.rejectWithValue(error as Error);
        }
    }
);



const storeIcProjectsSlice = createSlice({
    name: 'storeIcprojects',
    initialState,
    reducers: {},
});

export default storeIcProjectsSlice.reducer;
