import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { baseUrl } from '../../utils/url-config';
import { axiosInstance } from '../../utils/axios-instance';
import { User } from "../../redux/users";
import { getUserInfo } from "../../utils/authentication";

export interface Product {
    createdAt: any;
    SKU: any;
    ID: number;
    OWNERID: number;
    NAME: string;
    DESCRIPTION: string;
    BRAND: string;
    CATEGORY: string;
    SUBCATEGORY: string;
    UNIT: string;
    SIZE: string;
    COLOR: string;
    WHOLESALEPRICE: number;
    WHOLESALEPRICECURRENCY: string;
    IMAGEPATHS: string;
    CREATEDBY: string;
    UPDATEDBY: string;
}

export interface ProductState {
    allProducts: Product[];
    currentProduct: any;
    loading: boolean;
    error: string | null;
}

const initialState: ProductState = {
    allProducts: [],
    currentProduct: {},
    loading: false,
    error: null
};

/**
 * Return all the prodcuts for the current user
 */
export const fetchAllProducts = createAsyncThunk<Product[], void, { rejectValue: Error }>(
    'store/fetchAllProducts/get',
    async (_, thunkAPI) => {
        try {
            const userInfo: User = getUserInfo();
            const response = await axiosInstance.get<Product[]>(`${baseUrl}/products?ownerId=${userInfo.ID}`);
            return response.data;
        } catch (error: unknown) {
            return thunkAPI.rejectWithValue(error as Error);
        }
    }
);

/**
 * Return the Product by id
 */
export const fetchProductInfo = createAsyncThunk<Product, string, { rejectValue: Error }>(
    'store/fetchProductInfo/get',
    async (id, thunkAPI) => {
        try {
            const response = await axios.get<Product>(`${baseUrl}/products/${id}`);
            return response.data;
        } catch (error: unknown) {
            return thunkAPI.rejectWithValue(error as Error);
        }
    }
);

/**
 * Create a new Product
 */
export const addProduct = createAsyncThunk<Product, Product, { rejectValue: Error }>(
    'store/addProduct/post',
    async (product, thunkAPI) => {
        try {
            const response = await axiosInstance.post<Product>(`${baseUrl}/products/create`, product);
            return response.data;
        } catch (error: unknown) {
            return thunkAPI.rejectWithValue(error as Error);
        }
    }
);

/**
 * Update a new Product
 */
export const updateProduct = createAsyncThunk<Product, Product, { rejectValue: Error }>(
    'store/addProduct/update',
    async (product, thunkAPI) => {
        try {
            const response = await axiosInstance.patch<Product>(`${baseUrl}/products/update/${product.ID}`, product);
            return response.data;
        } catch (error: unknown) {
            return thunkAPI.rejectWithValue(error as Error);
        }
    }
);



const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllProducts.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchAllProducts.fulfilled, (state, action: PayloadAction<Product[]>) => {
                state.loading = false;
                state.allProducts = action.payload;
            })
            .addCase(fetchAllProducts.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload;
            }).addCase(fetchProductInfo.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchProductInfo.fulfilled, (state, action: PayloadAction<Product>) => {
                state.loading = false;
                state.currentProduct = action.payload;
            })
            .addCase(fetchProductInfo.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default productsSlice.reducer;
