import { redirect } from 'react-router-dom';
import { getStorage } from "firebase/storage";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { User } from '../redux/users';

export const getLoginToken = () => {
    const authToken = window.localStorage.getItem('accessToken');
    return authToken;
}

export const getCloudUid = () => {
    const uid = window.localStorage.getItem('uid');
    return uid;
}

export const setUserInfo = (userInfo: User) => {
    window.localStorage.setItem('userInfo', JSON.stringify(userInfo));
}

export const getUserInfo = () => {
    return JSON.parse(window.localStorage.getItem('userInfo')!);
}

export function checkAuthLoader() {
    const token = getLoginToken();
    const tokenDuration = getTokenDuration();

    if (!token || tokenDuration < 0) {
        window.localStorage.clear();
        return redirect('/initial');
    }
    return null;
}

export function backToHomeLoader() {
    const token = getLoginToken();
    const tokenDuration = getTokenDuration();

    if (token && tokenDuration > 0) {
        return redirect('/home');
    }
    return null;
}

export function setExpirationToken() {
    const expiration = new Date();
    expiration.setHours(expiration.getHours() + 1);
    localStorage.setItem('expiration', expiration.toISOString());
}

export function getTokenDuration() {
    const storedExpirationDate = localStorage.getItem('expiration');
    const expirationDate = new Date(storedExpirationDate || 0);
    const now = new Date();
    const duration = expirationDate.getTime() - now.getTime();
    return duration;
}

const firebaseConfig = {
    apiKey: "AIzaSyB1-PYMChU837ykMYpTDyDjyBJiKAKmeLM",
    authDomain: "aesthetic-codex-408802.firebaseapp.com",
    storageBucket: "aesthetic-codex-408802.appspot.com"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const storage = getStorage(app);

export const fireBaseBaseUrl = 'https://firebasestorage.googleapis.com/v0/b/aesthetic-codex-408802.appspot.com/o/'

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export default app;
