import { useState } from "react";
import SiteHeader from "../../../components/siteHeader/siteHeader";
import { Box, LinearProgress, Stack, TextField, useMediaQuery } from "@mui/material";
import DataTable from "../../../components/projectTable/projectTable";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store";
import { selectAllAvailableICProjects } from "../../../selectors/icprojects";
import { ICProject, fetchAllAvailableICProjects } from "../../../redux/projects";
import { useEffect } from "react";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../../utils/authentication";

function ApplyNew() {
    const [searchTerm, setSearchTerm] = useState('');

    const dispatch = useDispatch<AppDispatch>();
    const allAvailableICProjects: ICProject[] = useSelector(selectAllAvailableICProjects);
    const [allAvailableProjects, setAllAvailableProjects] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const isMobile = useMediaQuery('(max-width: 600px)');

    useEffect(() => {
        dispatch(fetchAllAvailableICProjects());
    }, [dispatch]);

    useEffect(() => {
        setIsLoading(true);
        const allActiveProjects = allAvailableICProjects.map(async project => {
            const productImagePaths = project.Product ? JSON.parse(project.Product.IMAGEPATHS) : [];
            const urls: string[] = [];
            if (productImagePaths.length) {
                await Promise.all(
                    productImagePaths.map(async (productImagePath: string | undefined) => {
                        const imageRef = ref(storage, productImagePath);
                        await getDownloadURL(imageRef).then((url) => {
                            urls.push(url);
                        });
                    })
                );
            }
            return { ...project, imageUrls: urls };
        });

        Promise.all(allActiveProjects).then(projects => {
            setAllAvailableProjects(projects);
            setIsLoading(false);
        });

    }, [allAvailableICProjects]);

    const rows = allAvailableProjects.filter((project) => {
        return project?.NAME?.toLowerCase().includes(searchTerm)
    }).map((icproject) => ({
        id: icproject.ID,
        project: icproject.NAME,
        description: icproject.DESCRIPTION,
        reviewCycle: icproject.REVIEWCYCLE,
        startDate: icproject.STARTDATE,
        endDate: icproject.ENDDATE,
        earningType: icproject.EARNINGTYPE,
        earningValue: icproject.EARNINGVALUE,
        product: icproject.Product,
        img: icproject.imageUrls.length ? icproject.imageUrls[0] : 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
    }));

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    return (
        <>
            <SiteHeader pageTitle='FIELD AGENT PORTAL' />
            <Box display='flex' justifyContent='center' marginTop={isMobile ? '20%' : '13%'}>
                <TextField
                    label="SEARCH PROJECT"
                    color="primary"
                    size="small"
                    variant="standard" onChange={handleSearchChange} />
            </Box>

            <Box marginTop='5%' display='flex' justifyContent='center'>
                {isLoading && <Stack sx={{ width: '50%', color: 'grey.500', position: 'absolute' }} spacing={2}>
                    <LinearProgress color="success" />
                </Stack>}
                {rows.length === 0 && !isLoading ? <h2>No results found</h2> : <DataTable rows={rows} />}
            </Box>
        </>
    )
}

export default ApplyNew;