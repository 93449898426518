import { Box } from "@mui/material";
import SiteHeader from "../../components/siteHeader/siteHeader";
import PortalCard from "../../components/portalCard/portalCard";
import { PortalCardInfo } from '../../components/portalCard/portalCard.interface';
import DesktopMacOutlinedIcon from '@mui/icons-material/DesktopMacOutlined';
import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';
import BookmarksOutlinedIcon from '@mui/icons-material/BookmarksOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from "react";
import { User, fetchUidUserInfo, assignUserType } from "../../redux/users";
import { AppDispatch } from "../../store";
import { selectCurrentUser } from "../../selectors/users";
import { getCloudUid, setUserInfo } from "../../utils/authentication";
import { useNavigate } from "react-router-dom";
import { UserType } from "../../utils/constants";

function HomePage() {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const currentLoginUser: User = useSelector(selectCurrentUser);
    setUserInfo(currentLoginUser);
    const currentUserTypeId = currentLoginUser.USERTYPEID;
    const currentUserId = currentLoginUser.ID;

    const clickAssignUserType = (userId: number, userTypeId: number) => {
        dispatch(assignUserType({ userId, userTypeId }))
    }
    const portalCards: PortalCardInfo[] = [
        { id: 1, displayInfo: 'Become an IC Field Agent', navigateTo: '/field-agent-portal', displaySvg: ContactMailOutlinedIcon, onClick: () => { clickAssignUserType(Number(currentUserId), UserType['FIELDAGENT']) } },
        { id: 2, displayInfo: 'Become an IC Owner', navigateTo: '/ic-owner-portal', displaySvg: BookmarksOutlinedIcon, onClick: () => { clickAssignUserType(Number(currentUserId), UserType['ICOWNER']) } },
        { id: 3, displayInfo: 'Become an IC Planner', navigateTo: '/ic-planner', displaySvg: DesktopMacOutlinedIcon, onClick: () => { clickAssignUserType(Number(currentUserId), UserType['ICPLANNER']) } }
    ];
    const uid = getCloudUid();

    useEffect(() => {
        if (uid) {
            dispatch(fetchUidUserInfo(uid));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (UserType[currentUserTypeId] === 'FIELDAGENT') {
            navigate("/field-agent-portal");
        }
        if (UserType[currentUserTypeId] === 'ICOWNER') {
            navigate("/ic-owner-portal");
        }
        if (UserType[currentUserTypeId] === 'ICPLANNER') {
            navigate("/ic-planner");
        }
    }, [currentUserTypeId, navigate])



    return (
        <>
            <SiteHeader pageTitle='HOME PAGE' />
            <Box
                display='flex'
                flexWrap='wrap'
                justifyContent='space-between'
                margin='2rem'
                marginTop='7rem'
                gap='1rem'
            >
                {portalCards.map(portalCard => (
                    <Box key={portalCard.id} width={{ xs: '100%', sm: '48%', md: '30%' }}>
                        <PortalCard portalCard={portalCard} />
                    </Box>
                ))}
            </Box>
        </>
    );
}

export default HomePage;