import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const myProjectSlice = createSlice({
    name: 'myProject',
    initialState: { selectedProject: {} },
    reducers: {
        setSelectedProject(state, action: PayloadAction<any>) {
            state.selectedProject = action.payload;
        },
        clearSelectedProject(state) {
            state.selectedProject = {};
        }
    }
});

export const { setSelectedProject, clearSelectedProject } = myProjectSlice.actions;

export default myProjectSlice.reducer;
