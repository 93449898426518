import { useState } from "react";
import SiteHeader from "../../components/siteHeader/siteHeader";
import { Box } from "@mui/material";
import DataTable from "../../components/storeTable/storeTable";
import ICProjectFormModal from "../../components/projectFormModal/projectFormModal";


function ICPlannerPortal() {
    const [openModal, setOpenModal] = useState(false);
    return (
        <>
            <SiteHeader pageTitle='IC PLANNER PORTAL' />
            <Box marginTop='5%' display='flex' justifyContent='center'>
                <DataTable />
            </Box>
            <Box>
                <ICProjectFormModal isModalOpen={openModal} setIsModalOpen={setOpenModal} />
            </Box>
        </>
    )
}

export default ICPlannerPortal;
