import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import Router from './Router';

const outerTheme = createTheme({
    palette: {
        primary: {
            main: orange[500],
        },
    },
});

const App = () => (
    <ThemeProvider theme={outerTheme}>
        <CssBaseline />
        <Router />
    </ThemeProvider>
)

export default App;
