import { SetStateAction, useEffect, useState } from "react";
import SiteHeader from "../../../components/siteHeader/siteHeader";
import { Box, FormControl, InputLabel, MenuItem, Select, TextField, useMediaQuery } from "@mui/material";
import TaskHistoryModal from "../../../components/myTasksModal/taskHistoryModal";
import UpdateTaskModal from "../../../components/myTasksModal/updateTaskModal";
import { AppDispatch } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { selectAllICProjectsByAgent } from "../../../selectors/fieldagenticprojects";
import { FieldAgentICProject, fetchAllStoreICProjectsByAgent } from "../../../redux/agentprojects";
import MyTaskCardList from "./myTaskCardList";
import { clearSelectedTask } from "../../../store/myTaskSlice";
import { storage } from "../../../utils/authentication";
import { getDownloadURL, ref } from "firebase/storage";

function MyTasks() {
    const [openTaskHistoryModal, setOpenTaskHistoryModal] = useState(false);
    const [openUpdateTaskModal, setOpenUpdateTaskModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedStore, setSelectedStore] = useState(0);
    const [allActiveProjects, setAllActiveProjects] = useState<FieldAgentICProject[]>([]);

    const dispatch = useDispatch<AppDispatch>();
    const allActiveICProjectsByAgent: FieldAgentICProject[] = useSelector(selectAllICProjectsByAgent);
    const isMobile = useMediaQuery('(max-width: 600px)');

    useEffect(() => {
        dispatch(fetchAllStoreICProjectsByAgent());
    }, [dispatch]);

    useEffect(() => {
        return () => {
            dispatch(clearSelectedTask());
        };
    }, [dispatch]);

    useEffect(() => {
        const allActiveProjects = allActiveICProjectsByAgent.map(async project => {
            const productImagePaths = JSON.parse(project.productImagePaths);
            const urls: string[] = [];
            if (productImagePaths.length) {
                await Promise.all(
                    productImagePaths.map(async (productImagePath: string | undefined) => {
                        const imageRef = ref(storage, productImagePath);
                        await getDownloadURL(imageRef).then((url) => {
                            urls.push(url);
                        });
                    })
                );
            }
            return { ...project, imageUrls: urls };
        });

        Promise.all(allActiveProjects).then(projects => {
            setAllActiveProjects(projects);
        });

    }, [allActiveICProjectsByAgent]);

    const rows = allActiveProjects.filter((project) => {
        return project?.ICProject?.NAME?.toLowerCase().includes(searchTerm)
    }).filter((project) => {
        if (selectedStore === 0) {
            return true
        }
        return project?.storeId === selectedStore
    }).map((agentIcProject) => {
        return {
            id: agentIcProject.ID,
            statusId: agentIcProject.STATUSID,
            usserId: agentIcProject.FIELDAGENTID,
            icProjectId: agentIcProject.ICPROJECTID,
            appliedTime: agentIcProject.APPLIEDDATETIME,
            createdBy: agentIcProject.CREATEDBY,
            discontinuedTime: agentIcProject.DISCONTINUEDDATETIME,
            icProjectName: agentIcProject?.ICProject?.NAME,
            icProjectDescription: agentIcProject?.ICProject?.DESCRIPTION,
            earningValue: agentIcProject?.ICProject?.EARNINGVALUE,
            earningType: agentIcProject?.ICProject?.EARNINGTYPE,
            reviewCycle: agentIcProject?.ICProject?.REVIEWCYCLE,
            startDate: agentIcProject?.ICProject?.STARTDATE,
            endDate: agentIcProject?.ICProject?.ENDDATE,
            userName: agentIcProject?.User?.NAME,
            img: agentIcProject.imageUrls?.length ? agentIcProject.imageUrls[0] : 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
            productImagePaths: JSON.parse(agentIcProject.productImagePaths),
            storeName: agentIcProject.storeName,
            productName: agentIcProject.productName,
            storeId: agentIcProject.storeId,
            isleLocation: agentIcProject?.StoreICProject?.AISLELOCATION,
            transactionHistory: agentIcProject.FieldAgentICProjectTransactions
        }
    });

    const stores = allActiveICProjectsByAgent.map((row) => { return { storeName: row.storeName, storeId: row.storeId } }).filter((value, index, self) => self.findIndex((t) => (t.storeName === value.storeName)) === index);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    const handleStoreChange = (event: { target: { value: SetStateAction<any>; }; }) => {
        setSelectedStore(event.target.value);
    };

    return (
        <>
            <SiteHeader pageTitle='FIELD AGENT PORTAL' />
            <Box display='flex' justifyContent='center' marginTop={isMobile ? '20%' : '13%'}>
                <FormControl variant="standard" sx={{ width: 300, margin: 'auto' }}>
                    <InputLabel id="select-store">Select a store</InputLabel>
                    <Select
                        labelId="select-store"
                        id="select-store"
                        value={selectedStore}
                        label="Select a store"
                        onChange={handleStoreChange}
                    >
                        <MenuItem value={0}>All Stores</MenuItem>
                        {stores.map((store) => (
                            <MenuItem key={store.storeId} value={store.storeId}>{store.storeName}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            <Box display='flex' justifyContent='center' marginTop='2%'>
                <TextField
                    label="SEARCH PROJECT"
                    color="primary"
                    size="small"
                    variant="standard" onChange={handleSearchChange} />
            </Box>
            <Box marginTop='5%' display='flex' justifyContent='center'>
                {rows.length === 0 ? <h2>No result</h2> : <MyTaskCardList rows={rows} openTaskHistoryModal={openTaskHistoryModal} setOpenTaskHistoryModal={setOpenTaskHistoryModal} openUpdateTaskModal={openUpdateTaskModal} setOpenUpdateTaskModal={setOpenUpdateTaskModal} />}
            </Box>
            <Box>
                <TaskHistoryModal isModalOpen={openTaskHistoryModal} setIsModalOpen={setOpenTaskHistoryModal} />
            </Box>
            <Box>
                <UpdateTaskModal isModalOpen={openUpdateTaskModal} setIsModalOpen={setOpenUpdateTaskModal} />
            </Box>
        </>
    )
}

export default MyTasks;
