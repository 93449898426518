import { useState, ChangeEvent, MouseEvent } from 'react';
import {
    Box,
    Modal,
    Button,
    FormControl,
    Typography,
    Card,
    CardMedia,
    Grid,
    FormControlLabel,
    TextField,
    RadioGroup,
    Radio
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getUserInfo, storage } from '../../utils/authentication';
import { User } from '../../redux/users';
import { AppDispatch } from '../../store';
import { FieldAgentICProjectTransaction, createFieldAgentICProjectTransactionInfo } from '../../redux/agentprojecttransactions';
import { v4 as uuidv4 } from 'uuid';
import {
    ref,
    uploadBytes
} from "firebase/storage";
import { fetchAllStoreICProjectsByAgent } from '../../redux/agentprojects';
import { createStoreICProjectTransactionWorkflowInfo } from '../../redux/agentprojecttransactionsworkflow';

const inputField = {
    marginTop: '5%'
}

interface UpdateTaskModalProps {
    isModalOpen: boolean;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const style: React.CSSProperties = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: '600px',
    background: 'white',
    border: '2px solid grey',
    borderRadius: '8px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    padding: 4,
    overflowY: 'auto',
    maxHeight: '90vh'
};
const displayedText = {
    marginTop: '1.5rem'
}

const formControl: React.CSSProperties = {
    width: '100%',
};

const cancelButtonStyle: React.CSSProperties = {
    marginRight: '1rem',
};


function UpdateTaskModal({ isModalOpen, setIsModalOpen }: UpdateTaskModalProps) {
    const userInfo: User = getUserInfo();
    const dispatch = useDispatch<AppDispatch>();

    const [selectedImages, setSelectedImages] = useState<string[]>([]);
    const [countOnShelf, setCountOnShelf] = useState<number>(0);
    const [selectedImageFiles, setSelectedImageFiles] = useState<File[]>([]);
    const [estimateOnShelf, setEstimateOnShelf] = useState<number>(0);


    const handleCountOnShelfChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const countOnShelf = Number(event.target.value);
        setCountOnShelf(countOnShelf);
    }

    const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            setSelectedImageFiles(Array.from(files));
            const imagesArray: string[] = [];
            for (let i = 0; i < files.length; i++) {
                const reader = new FileReader();
                reader.onload = (e: ProgressEvent<FileReader>) => {
                    if (e.target && e.target.result) {
                        imagesArray.push(e.target.result as string);
                        if (imagesArray.length === files.length) {
                            setSelectedImages(imagesArray);
                        }
                    }
                };
                reader.readAsDataURL(files[i]);
            }
        }
    };


    const handleClose = (event: MouseEvent<HTMLElement>, reason: string) => {
        event.preventDefault();
        if (reason === 'backdropClick') {
            return;
        }
        setIsModalOpen(false);
    };

    const handleButtonClose = () => setIsModalOpen(false);

    //retrieve the current selected ICProject
    const rowData = useSelector((state: any) => state.myTask.selectedTask)

    const handleButtonSubmit = async () => {
        const fieldAgentId = Number(userInfo.ID);
        //TODO: EARNINGVALUE should be calculated based on the ICProject the earning type and earning value
        const imagePath = selectedImageFiles.map(selectedImage => `${userInfo.CLOUDID}/field-agent-ic-project/${selectedImage.name + uuidv4()}`);
        const payload = { userId: fieldAgentId, FIELDAGENTICPROJECTID: rowData.id, PICTURESPATH: JSON.stringify(imagePath), ESTIMATEDCOUNTONSHELF: countOnShelf, ESTIMATECOUNTONSHELF: estimateOnShelf, EARNINGVALUE: rowData.earningValue, EARNINGCURRENCY: "USD" };

        const transactionResponse = await dispatch(createFieldAgentICProjectTransactionInfo(payload));
        const transactionId = (transactionResponse.payload as FieldAgentICProjectTransaction).ID;
        const workflowPayload = { FIELDAGENTSTOREICPROJECTTRANSACTIONID: transactionId, EARNINGSTATUSID: 1, userId: fieldAgentId, CREATEDBY: userInfo.NAME ? userInfo.NAME : userInfo.EMAIL, UPDATEDBY: userInfo.NAME ? userInfo.NAME : userInfo.EMAIL };

        //TODO: Dachent, the following code is recommended by copilot to ensure data integrity. Please review and modify as needed.
        await Promise.all([
            dispatch(createStoreICProjectTransactionWorkflowInfo(workflowPayload)),
            dispatch(fetchAllStoreICProjectsByAgent())
        ]);
        
        selectedImageFiles.forEach((selectedImage, index) => {
            uploadFile(selectedImage, imagePath[index]);
        })
        setIsModalOpen(false);
        setSelectedImageFiles([]);
        setSelectedImages([]);
        dispatch(fetchAllStoreICProjectsByAgent());
    };

    const { storeName, productName, isleLocation } = rowData;

    const uploadFile = (imageUpload: File, imagePath: string) => {
        if (!imageUpload) {
            return
        }
        const imageRef = ref(storage, imagePath);
        uploadBytes(imageRef, imageUpload).then((snapshot) => {
            console.log('successfully uploaded');
            console.log(imageRef);
            console.log(snapshot);
        });
    };

    return (
        <Box>
            <Modal
                open={isModalOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <FormControl variant="standard" sx={formControl}>
                        <Typography variant='h4' align="center" mb={2}>Update My IC Task</Typography>
                        <Box sx={displayedText}>
                            <Typography variant='body1'>Store:</Typography>
                            <Typography variant='h5'>{storeName}</Typography>
                        </Box>
                        <Box sx={displayedText}>
                            <Typography variant='body1'>Product Name:</Typography>
                            <Typography variant='h5'>{productName}</Typography>
                        </Box>
                        <Box sx={displayedText}>
                            <Typography variant='body1'>Aisle Location:</Typography>
                            <Typography variant='h5'>{isleLocation}</Typography>
                        </Box>

                        <Box mb={2}>
                            <input
                                accept="image/*"
                                style={{ display: 'none' }}
                                id="image-upload"
                                type="file"
                                multiple
                                onChange={handleImageChange}
                            />
                            <label htmlFor="image-upload">
                                <Button variant="contained" component="span" fullWidth>Upload Images</Button>
                            </label>
                        </Box>
                        {selectedImages.length > 0 && (
                            <Box maxWidth="md" mb={2}>
                                <Grid container spacing={2}>
                                    {selectedImages.map((image, index) => (
                                        <Grid item key={index} xs={12} sm={6} md={4}>
                                            <Card>
                                                <CardMedia
                                                    component="img"
                                                    alt={`Uploaded Image ${index + 1}`}
                                                    height="200"
                                                    image={image}
                                                />
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        )}
                        <Box mb={2}>
                            <Typography variant='body1' mb={1}>Do you want to complete the inventory count for us?</Typography>
                            <RadioGroup
                                value={estimateOnShelf}
                                onChange={event => setEstimateOnShelf(Number(event.target.value))}
                            >
                                <FormControlLabel value={1} control={<Radio />} label="Yes" />
                                <FormControlLabel value={0} control={<Radio />} label="No" />
                            </RadioGroup>
                        </Box>
                        <Box mb={2}>
                            <Typography variant='body1' mb={1}>Count on shelf</Typography>
                            <TextField
                                label="Count on shelf"
                                variant="filled"
                                sx={inputField}
                                onChange={handleCountOnShelfChange}
                                type="number"
                                disabled={estimateOnShelf !== 1} //only enable the input field when the user select yes
                                inputProps={{
                                    min: 0,
                                    step: 1
                                }}
                            />
                        </Box>
                        <Box display='flex' justifyContent='flex-end'>
                            <Button variant="contained" onClick={handleButtonSubmit} sx={cancelButtonStyle}>Submit</Button>
                            <Button variant="outlined" onClick={handleButtonClose} sx={cancelButtonStyle}>Cancel</Button>
                        </Box>
                    </FormControl>
                </Box>
            </Modal >
        </Box >
    );
}

export default UpdateTaskModal;