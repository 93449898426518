import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Button, FormControl, ImageList, ImageListItem, Typography } from '@mui/material';
import { User } from '../../redux/users';
import { getUserInfo, storage } from "../../utils/authentication";
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import { fetchAllAvailableICProjects, updateICProjectInfo } from '../../redux/projects';
import { Key, useEffect, useState } from 'react';
import { getDownloadURL, ref } from 'firebase/storage';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '2px solid grey',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    justifyContent: 'center',
    maxHeight: '90vh',
    overflow: 'scroll'
};

const formControl = {
    width: '90%'
}

const displayedText = {
    marginTop: '1.5rem'
}

const cancelSubmitButtons = {
    marginTop: '5%',
    width: '40%'
}

const contentStyle = {
    overflowY: 'auto',
    flexGrow: 1,
    padding: '16px',
    maxHeight: '40rem',
    width: '70vw'
};

function ProjectModal({ isModalOpen, setIsModalOpen, rowData }: any) {
    const userInfo: User = getUserInfo();
    const dispatch = useDispatch<AppDispatch>();
    const [productImages, setProductImages] = useState<any[]>([]);

    const handleClose = (event: React.MouseEvent<HTMLElement>, reason: string) => {
        event.preventDefault();
        if (reason === 'backdropClick') {
            return
        }
        setIsModalOpen(false)
    };

    const handleButtonClose = () => setIsModalOpen(false);

    const { reviewCycle,
        startDate,
        endDate,
        earningType,
        earningValue,
        description,
        project,
        id } = rowData;

    const handleButtonSubmit = async () => {
        const ownerId = userInfo.ID;
        const payload = { ownerId, projectId: id, availability: false } as any;
        await dispatch(updateICProjectInfo(payload));
        dispatch(fetchAllAvailableICProjects());
        setIsModalOpen(false);
    }

    useEffect(() => {
        const productImagePath = rowData && rowData.product ? JSON.parse(rowData.product.IMAGEPATHS) : [];
        if (productImagePath.length > 0) {
            Promise.all(productImagePath.map(async (imagePath: string) => {
                const imageRef = ref(storage, imagePath);
                return await getDownloadURL(imageRef);
            })).then((urls) => {
                setProductImages(urls.map((url: string) => ({ img: url, title: 'Image' })));
            });
        } else {
            setProductImages([]);
        }
    }, [rowData]);

    return (
        <Box>
            <Modal
                open={isModalOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <FormControl variant="standard" sx={formControl}>
                        <Box display='flex' justifyContent='center'>
                            <Typography variant='h3'>Apply IC Project</Typography>
                        </Box>
                        <Box sx={contentStyle}>
                            <Box sx={displayedText}>
                                <Typography variant='body1'>Project:</Typography>
                                <Typography variant='h5'>{project}</Typography>
                            </Box>
                            <Box sx={displayedText}>
                                <Typography variant='body1'>Description:</Typography>
                                <Typography variant='h5'>{description}</Typography>
                            </Box>
                            <Box sx={displayedText}>
                                <Typography variant='body1'>Review Cycle:</Typography>
                                <Typography variant='h5'>{reviewCycle}</Typography>
                            </Box>
                            <Box sx={displayedText}>
                                <Typography variant='body1'>Start Date</Typography>
                                <Typography variant='h5'>{startDate}</Typography>
                            </Box>
                            <Box sx={displayedText}>
                                <Typography variant='body1'>End Date</Typography>
                                <Typography variant='h5'>{endDate}</Typography>
                            </Box>
                            <Box sx={displayedText}>
                                <Typography variant='body1'>Earning Type</Typography>
                                <Typography variant='h5'>{earningType}</Typography>
                            </Box>
                            <Box sx={displayedText}>
                                <Typography variant='body1'>Earning Value</Typography>
                                <Typography variant='h5'>{earningValue}</Typography>
                            </Box>
                            {productImages.length ? <Box>
                                <Typography variant='body1'>Product Images</Typography>
                                <ImageList sx={{ width: '35vw', height: '40vh' }} cols={2} rowHeight={300}>
                                    {productImages.map((item: { img: Key | null | undefined; title: string | undefined; }) => (
                                        <ImageListItem key={item.img}>
                                            <img
                                                srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                                src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                                                alt={item.title}
                                                loading="lazy"
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box> : null}
                        </Box>
                        <Box display='flex' justifyContent='space-between'>
                            <Button variant="contained" onClick={handleButtonSubmit} sx={cancelSubmitButtons}>Apply</Button>
                            <Button variant="outlined" onClick={handleButtonClose} sx={cancelSubmitButtons}>Cancel</Button>
                        </Box>

                    </FormControl>
                </Box>
            </Modal>
        </Box>
    );
}

export default ProjectModal;
