import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { styled } from "@mui/material/styles";
import { IconButton, InputAdornment } from '@mui/material';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../utils/authentication';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { createNewUser } from '../../redux/users';
import { AppDispatch } from '../../store';
import { useDispatch } from 'react-redux';
import SiteHeader from '../../components/siteHeader/siteHeader';

const defaultTheme = createTheme();

const SignUpContainer = styled(Grid)(({ theme }) => ({
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'linear-gradient(to right bottom, #4d6bca, #2a3a8e)', // Background gradient
    backgroundSize: 'cover',
}));

export default function SignUpPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const [password, setPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [error, setError] = React.useState('');
    const [showPassword, setShowPassword] = React.useState(false);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const email = String(data.get('email'));
        if (!email || !password || !confirmPassword) {
            setError('Please fill in all fields.');
            return;
        }

        if (password !== confirmPassword) {
            setError('Passwords do not match.');
            return;
        }

        const passwordPattern = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/;
        if (!passwordPattern.test(password)) {
            setError(
                'Password must be at least 8 characters long, contain at least one uppercase letter, and one special character (!@#$%^&*).'
            );
            return;
        }

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            console.log(user);
            const newUserInfo = {
                CLOUDID: user.uid,
                USERTYPEID: 1,
                NAME: user.email?.split("@")[0],
                EMAIL: user.email,
                PHONE: 'null',
                STARTDATE: new Date(),
                USERTYPESTARTDATE: new Date(),
                CREATEDBY: user.email?.split("@")[0],
                UPDATEDBY: user.email?.split("@")[0]
            }
            dispatch(createNewUser(newUserInfo))
            navigate("/login");
        } catch (err: any) {
            const errorCode = err.code;
            const errorMessage = err.message;
            switch (errorCode) {
                case 'auth/invalid-email':
                    setError('Invalid email address.');
                    break;
                case 'auth/email-already-in-use':
                    setError('Email already exists');
                    break;
                default:
                    setError('Validation error');
                    break;
            }
            console.log(errorCode);
            console.log(errorMessage);
        }
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    };

    const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(event.target.value);
    };

    const togglePasswordVisibility = () => {
        setShowPassword((prevState) => !prevState);
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <SiteHeader pageTitle='Sign Up' />
            <SignUpContainer container sx={{
                minHeight: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} sx={{ backgroundColor: '#E6E6E6' }}>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}
                    >
                        <Typography component="h1" variant="h5">
                            Sign up to myOpenIC
                        </Typography>
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3, width: '100%' }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                variant="standard"
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type={showPassword ? 'text' : 'password'}
                                id="password"
                                autoComplete="new-password"
                                variant="standard"
                                value={password}
                                onChange={handlePasswordChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={togglePasswordVisibility}
                                                edge="end"
                                                aria-label="toggle password visibility"
                                            >
                                                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="confirmPassword"
                                label="Confirm Password"
                                type={showPassword ? 'text' : 'password'}
                                id="confirmPassword"
                                autoComplete="new-password"
                                variant="standard"
                                value={confirmPassword}
                                onChange={handleConfirmPasswordChange}
                                error={error !== ''}
                                helperText={error}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 2 }}
                            >
                                Sign Up
                            </Button>
                            <Button
                                fullWidth
                                variant="contained"
                                sx={{ mt: 2, color: 'white', backgroundColor: 'grey' }}
                                component={RouterLink}
                                to="/sign-in" // Link to sign-in page
                            >
                                Sign In
                            </Button>
                            <Typography sx={{ mt: 3 }}>Questions? Email us at <a href="mailto:Support@myopenic.com">Support@myopenic.com</a></Typography>
                        </Box>
                    </Box>
                </Grid>
            </SignUpContainer>
        </ThemeProvider>
    );
}