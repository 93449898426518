import { Box, Card, CardActions, CardContent, CardHeader, CardMedia, Collapse, Grid, IconButton, IconButtonProps, Menu, MenuItem, Pagination, Typography, styled } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { clearSelectedProduct, setSelectedProduct } from "../../../store/myProductSlice";
import { toLocalTime } from "../../../utils/to-local-time";

interface RowData {
    id: number;
    project: string;
    reviewCycle: string;
    description: string;
    startDate: Date;
    endDate: Date;
    earningType: string;
    earningValue: number;
    img: string;
}

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const ICOwnerProductCardList = ({ rows, setIsProductUpdateModalOpen }: any) => {
    const [expandedId, setExpandedId] = React.useState<number | null>(null);
    const [page, setPage] = React.useState(1);
    const itemsPerPage = 9;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [currentMenuRow, setCurrentMenuRow] = React.useState<RowData | null>(null);
    const dispatch = useDispatch();
    const onProductUpdateClick = () => {
        dispatch(setSelectedProduct(currentMenuRow));
        setIsProductUpdateModalOpen(true);
    };

    useEffect(() => {
        return () => {
            clearSelectedProduct();
        }
    }, []);

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, row: RowData) => {
        setAnchorEl(event.currentTarget);
        setCurrentMenuRow(row);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleExpandClick = (id: number) => {
        setExpandedId(id === expandedId ? null : id);
    };


    const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
        setPage(newPage);
    };



    const paginatedRows = rows.slice((page - 1) * itemsPerPage, page * itemsPerPage);

    return (
        <Box style={{ height: 400, width: '50%' }}>
            <Grid container spacing={2}>
                {paginatedRows.map((row: any) => (
                    <Grid item key={row.id} xs={12} sm={6} md={4}>
                        <Card sx={{ marginBottom: '20px' }}>
                            <CardHeader
                                action={
                                    <>
                                        <IconButton aria-label="settings" onClick={(e) => handleMenuOpen(e, row)}>
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            id="action-menu"
                                            anchorEl={anchorEl}
                                            open={Boolean(anchorEl)}
                                            onClose={handleMenuClose}
                                        >
                                            <MenuItem onClick={() => { onProductUpdateClick(); handleMenuClose() }}>Update Product</MenuItem>
                                        </Menu>
                                    </>
                                }
                                title={row.name}
                            />
                            <CardMedia
                                component="img"
                                height="194"
                                image={row.img}
                                alt={row.name}
                                sx={{ objectFit: 'contain' }}
                            />
                            <CardContent>
                                <Typography variant="body2" color="text.secondary">
                                    Available since {toLocalTime(row.createdAt)}
                                </Typography>
                            </CardContent>
                            <CardActions disableSpacing>
                                <ExpandMore
                                    onClick={() => handleExpandClick(row.id)}
                                    aria-expanded={expandedId === row.id}
                                    aria-label="show more"
                                    expand={expandedId === row.id}
                                >
                                    <ExpandMoreIcon />
                                </ExpandMore>
                            </CardActions>
                            <Collapse in={expandedId === row.id} timeout="auto">
                                <CardContent>
                                    <Typography>
                                        Description: {row.description ? row.description : 'unavailable'}
                                    </Typography>
                                    <Typography>
                                        Brand: {row.brand ? row.brand : 'unavailable'}
                                    </Typography>
                                    <Typography>
                                        Category: {row.category ? row.category : 'unavailable'}
                                    </Typography>
                                    <Typography paragraph>Whole sale price {row.wholesalePrice !== "0" ? row.wholesalePrice : 'unavailable'} {row.wholeSelePriceCurrency}</Typography>
                                </CardContent>
                            </Collapse>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <Pagination
                count={Math.ceil(rows.length / itemsPerPage)}
                page={page}
                onChange={handleChangePage}
                color="primary"
                style={{ marginTop: '20px', justifyContent: 'center', display: 'flex' }}
            />
        </Box>
    )
}

export default ICOwnerProductCardList;
