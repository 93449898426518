import { useEffect, useState } from "react";
import SiteHeader from "../../../components/siteHeader/siteHeader";
import { Box, Button, Stack, LinearProgress, TextField, useMediaQuery } from "@mui/material";
import ICProjectFormModal from "../../../components/projectFormModal/projectFormModal";
import { ICProject, fetchAllICProjects } from "../../../redux/projects";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store";
import { selectAllICProjects } from "../../../selectors/icprojects";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../../utils/authentication";
import IcOwnerProjectDataTable from "./icOwnerProjectTable";
import UpdateProjectModal from "./updateProjectModal";
import ReviewTransactionModal from "./reviewTransactionModal";
import TransactionHistoryModal from "./transactionHistoryModal";

function ICOwnerProjects() {
    const [openModal, setOpenModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isProjectModalOpen, setIsProjectModalOpen] = useState(false);
    const [isReviewTransactionModalOpen, setIsReviewTransactionModalOpen] = useState(false);
    const [isTransactionHistoryModalOpen, setIsTransactionHistoryModalOpen] = useState(false);
    const [icOwnerProjects, setIcOwnerProjects] = useState<any[]>([]);
    const isMobile = useMediaQuery('(max-width: 600px)');
    const addICProjectHandler = () => {
        setOpenModal(true);
    }

    const dispatch = useDispatch<AppDispatch>();
    const allICProjects: ICProject[] = useSelector(selectAllICProjects);

    useEffect(() => {
        dispatch(fetchAllICProjects());
    }, [dispatch]);

    useEffect(() => {
        setIsLoading(true);
        const allActiveProjects = allICProjects.map(async project => {
            const productImagePaths = project.Product ? JSON.parse(project.Product.IMAGEPATHS) : [];
            const urls: string[] = [];
            if (productImagePaths.length) {
                await Promise.all(
                    productImagePaths.map(async (productImagePath: string | undefined) => {
                        const imageRef = ref(storage, productImagePath);
                        await getDownloadURL(imageRef).then((url) => {
                            urls.push(url);
                        });
                    })
                );
            }
            return { ...project, imageUrls: urls };
        });

        Promise.all(allActiveProjects).then(projects => {
            setIcOwnerProjects(projects);
            setIsLoading(false);
        });

    }, [allICProjects]);

    const rows = icOwnerProjects.filter((project) => {
        return project?.NAME?.toLowerCase().includes(searchTerm)
    }).map((icproject) => ({
        id: icproject.ID,
        project: icproject.NAME,
        description: icproject.DESCRIPTION,
        reviewCycle: icproject.REVIEWCYCLE,
        startDate: icproject.STARTDATE,
        endDate: icproject.ENDDATE,
        earningType: icproject.EARNINGTYPE,
        earningValue: icproject.EARNINGVALUE,
        storeId: icproject.StoreICProject.STOREID,
        aisleLocation: icproject.StoreICProject.AISLELOCATION,
        productId: icproject.Product.ID,
        storeICProjectId: icproject.StoreICProject.ID,
        detailedInstruction: icproject.DETAILINSTRUCTION,
        img: icproject.imageUrls.length ? icproject.imageUrls[0] : 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
        fieldAgentICProject: icproject.FieldAgentICProject,
        hasPendingReviewTransaction: icproject.FieldAgentICProject && icproject.FieldAgentICProject.FieldAgentICProjectTransactions ? icproject.FieldAgentICProject.FieldAgentICProjectTransactions.some((FieldAgentICProjectTransaction: { EARNINGSTATUSID: number; }) => FieldAgentICProjectTransaction.EARNINGSTATUSID === 1) : false,
    })).sort((a, b) => {
        if (a.hasPendingReviewTransaction < b.hasPendingReviewTransaction) {
            return 1;
        }
        if (a.hasPendingReviewTransaction > b.hasPendingReviewTransaction) {
            return -1;
        }
        return 0;
    })

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    return (
        <>
            <SiteHeader pageTitle='IC OWNER(SUPPLIER) PORTAL' />
            <Box display='flex' justifyContent='center' marginTop={isMobile ? '20%' : '15%'}>
                <TextField label="Search IC Project" variant="standard" onChange={handleSearchChange} />
            </Box>
            <Box display='flex' justifyContent='center' marginTop='5%'>
                <Button variant="contained" onClick={addICProjectHandler}>Add Project</Button>
            </Box>
            <Box marginTop='5%' display='flex' justifyContent='center'>
                {isLoading && <Stack sx={{ width: '50%', color: 'grey.500', position: 'absolute' }} spacing={2}>
                    <LinearProgress color="success" />
                </Stack>}
                {rows.length === 0 && !isLoading ? <h2>No results found</h2> : <IcOwnerProjectDataTable rows={rows} setIsProjectModalOpen={setIsProjectModalOpen} setIsReviewTransactionModalOpen={setIsReviewTransactionModalOpen} setIsTransactionHistoryModalOpen={setIsTransactionHistoryModalOpen} />}
            </Box>
            <Box>
                <ICProjectFormModal isModalOpen={openModal} setIsModalOpen={setOpenModal} />
            </Box>
            <Box>
                <UpdateProjectModal isModalOpen={isProjectModalOpen} setIsModalOpen={setIsProjectModalOpen} />
            </Box>
            <Box>
                <ReviewTransactionModal isModalOpen={isReviewTransactionModalOpen} setIsModalOpen={setIsReviewTransactionModalOpen} />
            </Box>
            <Box>
                <TransactionHistoryModal isModalOpen={isTransactionHistoryModalOpen} setIsModalOpen={setIsTransactionHistoryModalOpen} />
            </Box>
        </>
    )
}

export default ICOwnerProjects;
