import axios from "axios";
import { baseUrl } from "./url-config";
import { getLoginToken } from "./authentication";

const bearerToken = getLoginToken();

export const axiosInstance = axios.create({
    baseURL: baseUrl,
    headers: {
        'Authorization': `Bearer ${bearerToken}`
    }
});
