export enum UserType {
    UNASSIGNED = 1,
    ADMIN,
    ICOWNER,
    FIELDAGENT,
    STOREMANAGER,
    ICPLANNER
}

export enum FieldAgentEarningStatus {
    SUBMITTED = 1,
    REVIEWED,
    REJECTED,
    APPROVED,
    PENDING,
    PROCESSING,
    COMPLETED,
    RECEIVED
}

export enum ICProjectTypes {
    STORE = 1,
    SHIPPING
}

export enum FieldAgentProjectStatus {
    ACTIVE = 1,
    INACTIVE,
    PENDING,
    REJECTED,
    APPROVED,
    DISCONTINUED_BY_FIELD_AGENT,
    DISCONTINUED_BY_IC_OWNER
}
