import { Box, Modal, Typography, Button, FormControl, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useSelector } from 'react-redux';
import { FieldAgentEarningStatus } from '../../../utils/constants';
import React, { useMemo } from 'react';
import { toLocalTime } from '../../../utils/to-local-time';


interface TaskHistoryModalProps {
    isModalOpen: boolean;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const style: React.CSSProperties = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: '800px',
    background: 'white',
    border: '2px solid #ccc',
    borderRadius: '8px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    padding: 5,
    overflowY: 'auto',
    maxHeight: '90vh'
};


const tableHeadCellStyle: React.CSSProperties = {
    fontWeight: 'bold',
};

const buttonContainerStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '2rem',
};

function TransactionHistoryModal({ isModalOpen, setIsModalOpen }: TaskHistoryModalProps) {
    const selectedTask = useSelector((state: any) => state.myTask.selectedTask);
    const selectedHistory = useMemo(() => {
        return selectedTask.FieldAgentICProjectTransactions ? selectedTask.FieldAgentICProjectTransactions : [];
    }, [selectedTask.FieldAgentICProjectTransactions]);

    const handleClose = (event: React.MouseEvent<HTMLElement>, reason: string) => {
        event.preventDefault();
        if (reason === 'backdropClick') {
            return;
        }
        setIsModalOpen(false);
    };

    const handleButtonSubmit = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Modal
                open={isModalOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <FormControl variant="standard">
                        <Box display='flex' justifyContent='center' margin='2rem'>
                            <Typography variant='h5'>Transaction History</Typography>
                        </Box>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={tableHeadCellStyle}>Store Visit Date/TIME</TableCell>
                                        <TableCell style={tableHeadCellStyle}>Earning</TableCell>
                                        <TableCell style={tableHeadCellStyle}>Payment Status</TableCell>
                                        <TableCell style={tableHeadCellStyle}>Supplier Rating</TableCell>
                                        <TableCell style={tableHeadCellStyle}>Supplier Comment</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {selectedHistory.filter((historyItem: { EARNINGSTATUSID: number; }) => historyItem.EARNINGSTATUSID !== 1).map((historyItem: any, index: number) => (
                                        <TableRow key={index}>
                                            <TableCell>{toLocalTime(historyItem.createdAt)}</TableCell>
                                            <TableCell>{historyItem.EARNINGVALUE} {historyItem.EARNINGCURRENCY}</TableCell>
                                            <TableCell>{FieldAgentEarningStatus[historyItem.EARNINGSTATUSID]}</TableCell>
                                            <TableCell>{historyItem.REVIEWRATING} Stars</TableCell>
                                            <TableCell>{historyItem.REVIEWCOMMENTS}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box sx={buttonContainerStyle}>
                            <Button variant="contained" onClick={handleButtonSubmit}>OK</Button>
                        </Box>
                    </FormControl>
                </Box>
            </Modal>

        </>
    );
}


export default TransactionHistoryModal;
