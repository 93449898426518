import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Image1 from './image1.jpg';
import Image2 from './image2.jpg';
import Image3 from './image3.jpg';
import SiteHeader from '../../components/siteHeader/siteHeader';

const HowToBecomeGoodAgent = () => {
    return (
        <>
            <SiteHeader pageTitle='FIELD AGENT PORTAL' />
            <Container maxWidth="lg">
                <Box my={4}>
                    <Typography variant="h3" gutterBottom>
                        How to Become a Good Field Agent
                    </Typography>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={6}>
                            <img src={Image1} alt="Good Inventory Control" style={{ width: '100%', borderRadius: '8px' }} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h5" gutterBottom>
                                What Makes a Good Field Agent?
                            </Typography>
                            <Typography variant="body1">
                                A proficient field agent understands the significance of effective inventory control. Utilizing high-quality pictures aids in maintaining accurate stock levels by providing detailed insights into inventory status.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={4} sx={{ mt: 4 }}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h5" gutterBottom>
                                The Importance of Detailed Images
                            </Typography>
                            <Typography variant="body1">
                                Detailed inventory control images, captured from various angles like top view, side view, and shelf level, empower inventory planners to gain comprehensive insights. Such pictures assist in precise unit counting and enable efficient product restocking in supermarkets.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <img src={Image2} alt="Inventory Planner in Supermarket" style={{ width: '100%', borderRadius: '8px' }} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={4} sx={{ mt: 4 }}>
                        <Grid item xs={12} md={6}>
                            <img src={Image3} alt="Field Agent Inventory" style={{ width: '100%', borderRadius: '8px' }} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h5" gutterBottom>
                                Utilizing Comprehensive Angles
                            </Typography>
                            <Typography variant="body1">
                                Field agents, through comprehensive angle images, enhance inventory control. Detailed pictures, capturing various shelf levels, allow better inventory counting and accurate replenishment strategies for supermarket products.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={4} sx={{ mt: 4 }}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h5" gutterBottom>
                                The Importance of Detailed Images
                            </Typography>
                            <Typography variant="body1">
                                Detailed inventory control images, captured from various angles like top view, side view, and shelf level, empower inventory planners to gain comprehensive insights. Such pictures assist in precise unit counting and enable efficient product restocking in supermarkets.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <img src={Image1} alt="Inventory Planner in Supermarket" style={{ width: '100%', borderRadius: '8px' }} />
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </>
    );
};

export default HowToBecomeGoodAgent;