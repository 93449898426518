import SignInSide from '../../pages/signInPage/signInSide';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import HomePage from '../../pages/homePage/homePage';
import { backToHomeLoader, checkAuthLoader } from '../../utils/authentication';
import WhyMyOpenIC from '../../pages/whyUs/whyUs';
import AboutUs from '../../pages/aboutUs/aboutUs';
import SignUpPage from '../../pages/signUpPage/signUpPage';
import ICOwnerPortal from '../../pages/icOwnerPortal/icOwnerPortal';
import ICOwnerProduct from '../../pages/icOwnerPortal/icOwnerProduct/icOwnerProduct';
import ICOwnerProjects from '../../pages/icOwnerPortal/icOwnerProjects/icOwnerProjects';
import ICFieldAgentPortal from '../../pages/icFieldAgentPortal/icFieldAgentPortal';
import ICPlannerPortal from '../../pages/icPlannerPortal/icPlannerPortal';
import ApplyNew from '../../pages/icFieldAgentPortal/applyNew/applyNew';
import MyTasks from '../../pages/icFieldAgentPortal/myTasks/myTasks';
import HowToBecomeGoodAgent from '../../pages/becomeGoodAgent/becomeGoodAgent';
import InitialPage from '../../pages/initial/initialPage';

const Router = () => {
    const routers = createBrowserRouter([
        { path: '/home', element: <HomePage />, loader: checkAuthLoader },
        { path: '/login', element: <SignInSide />, loader: backToHomeLoader },
        { path: '/why-us', element: <WhyMyOpenIC /> },
        { path: '/about-us', element: <AboutUs /> },
        { path: '/field-agent-portal', element: <ICFieldAgentPortal />, loader: checkAuthLoader },
        { path: '/field-agent-portal/apply-new', element: <ApplyNew />, loader: checkAuthLoader },
        { path: '/field-agent-portal/my-tasks', element: <MyTasks />, loader: checkAuthLoader },
        { path: '/field-agent-portal/how-to-become-ic-agent', element: <HowToBecomeGoodAgent />, loader: checkAuthLoader },
        { path: '/ic-owner-portal', element: <ICOwnerPortal />, loader: checkAuthLoader },
        { path: '/ic-owner-portal/product', element: <ICOwnerProduct />, loader: checkAuthLoader },
        { path: '/ic-owner-portal/project', element: <ICOwnerProjects />, loader: checkAuthLoader },
        { path: '/ic-planner', element: <ICPlannerPortal />, loader: checkAuthLoader },
        { path: '/sign-up', element: <SignUpPage /> },
        { path: '/initial', element: <InitialPage /> },
        { path: '*', element: <HomePage />, loader: checkAuthLoader }
    ])

    return <RouterProvider router={routers} />
}

export default Router;
