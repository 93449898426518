import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const myTaskSlice = createSlice({
    name: 'myTask',
    initialState: { selectedTask: {} },
    reducers: {
        setSelectedTask(state, action: PayloadAction<any>) {
            state.selectedTask = action.payload;
        },
        clearSelectedTask(state) {
            state.selectedTask = {};
        }
    }
});

export const { setSelectedTask, clearSelectedTask } = myTaskSlice.actions;

export default myTaskSlice.reducer;
