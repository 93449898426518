import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Button, Card, CardMedia, FormControl, Grid, TextField, Typography } from '@mui/material';
import { User } from '../../redux/users';
import { getUserInfo, storage } from "../../utils/authentication";
import { addProduct, fetchAllProducts } from '../../redux/products';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import { ChangeEvent, useState } from 'react';
import { ref, uploadBytes } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '2px solid grey',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    justifyContent: 'center',
    maxHeight: '90vh',
    overflow: 'scroll'
};

const formControl = {
    width: '70%'
}

const inputField = {
    marginTop: '5%'
}

const cancelSubmitButtons = {
    marginTop: '5%',
    width: '40%'
}

function ProductFormModal({ isModalOpen, setIsModalOpen }: any) {
    const userInfo: User = getUserInfo();
    const dispatch = useDispatch<AppDispatch>();
    const [productName, setProductName] = useState('');
    const [productDescription, setProductDescription] = useState('');
    const [productBrand, setProductBrand] = useState('');
    const [productCategory, setProductCategory] = useState('');
    const [productSubCategory, setProductSubCategory] = useState('');
    const [productWholesalePrice, setProductWholesalePrice] = useState('');
    const [productWholesalePriceCurrency, setProductWholesalePriceCurrency] = useState('');
    const [productUnit, setProductUnit] = useState('');
    const [productSize, setProductSize] = useState('');
    const [productColor, setProductColor] = useState('');
    const [productSku, setProductSku] = useState('');
    const [selectedImageFiles, setSelectedImageFiles] = useState<File[]>([]);
    const [selectedImages, setSelectedImages] = useState<string[]>([]);

    const handleClose = (event: React.MouseEvent<HTMLElement>, reason: string) => {
        event.preventDefault();
        if (reason === 'backdropClick') {
            return
        }
        setIsModalOpen(false)
    };

    const handleButtonClose = () => setIsModalOpen(false);

    /**
     * Handle the change of the product name and store it in the state
     * @param event 
     */
    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const productName = event.target.value;
        setProductName(productName);
    };

    const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const productDescription = event.target.value;
        setProductDescription(productDescription);
    };

    const handleBrandChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const productBrand = event.target.value;
        setProductBrand(productBrand);
    }

    const handleCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const productCategory = event.target.value;
        setProductCategory(productCategory);
    }

    const handleSubCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const productSubCategory = event.target.value;
        setProductSubCategory(productSubCategory);
    }

    const handleWholesalePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const productWholesalePrice = event.target.value;
        setProductWholesalePrice(productWholesalePrice);
    }

    const handleWholesalePriceCurrencyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        //const productWholesalePriceCurrency = event.target.value;
        const productWholesalePriceCurrency = "USD";
        setProductWholesalePriceCurrency(productWholesalePriceCurrency);
    }

    const handleUnitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const productUnit = event.target.value;
        setProductUnit(productUnit);
    }

    const handleSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const productSize = event.target.value;
        setProductSize(productSize);
    }

    const handleColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const productColor = event.target.value;
        setProductColor(productColor);
    }

    const handleSkuChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const sku = event.target.value;
        setProductSku(sku);
    }

    const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            setSelectedImageFiles(Array.from(files));
            const imagesArray: string[] = [];
            for (let i = 0; i < files.length; i++) {
                const reader = new FileReader();
                reader.onload = (e: ProgressEvent<FileReader>) => {
                    if (e.target && e.target.result) {
                        imagesArray.push(e.target.result as string);
                        if (imagesArray.length === files.length) {
                            setSelectedImages(imagesArray);
                        }
                    }
                };
                reader.readAsDataURL(files[i]);
            }
        }
    };

    const uploadFile = (imageUpload: File, imagePath: string) => {
        if (!imageUpload) {
            return
        }
        const imageRef = ref(storage, imagePath);
        return uploadBytes(imageRef, imageUpload).then((snapshot) => {
            console.log('successfully uploaded');
            console.log(imageRef);
            console.log(snapshot);
        });
    };

    const handleButtonSubmit = async () => {
        const ownerId = userInfo.ID;
        const imagePath = selectedImageFiles.map(selectedImage => `${userInfo.CLOUDID}/product/${selectedImage.name + uuidv4()}`);
        console.log("current brand value: " + productBrand + "..");
        const payload = {
            // // Sean, why you pass in ID here? ID is a primary key and will auto increment by database, no need it
            // ID: 0, // this is a dummy value, will be replaced by the backend
            // OWNERID is a string as it was stored in local storage, this is correct usage
            OWNERID: Number(ownerId), //TODO check with Dacheng on why user id is not a number
            NAME: productName,
            DESCRIPTION: productDescription,
            BRAND: productBrand,
            CATEGORY: productCategory,
            SUBCATEGORY: productSubCategory,
            WHOLESALEPRICE: Number(productWholesalePrice),
            WHOLESALEPRICECURRENCY: productWholesalePriceCurrency,
            UNIT: productUnit,
            SIZE: productSize,
            SKU: productSku,
            COLOR: productColor,
            IMAGEPATHS: JSON.stringify(imagePath),
            CREATEDBY: userInfo.NAME ? userInfo.NAME : userInfo.EMAIL,
            UPDATEDBY: userInfo.NAME ? userInfo.NAME : userInfo.EMAIL
        };

        await dispatch(addProduct(payload as any));
        await Promise.all(
            selectedImageFiles.map(async (selectedImage, index) => {
                await uploadFile(selectedImage, imagePath[index]);
            })
        ).then(() => {
            console.log('all images uploaded');
            dispatch(fetchAllProducts());
        })
        setIsModalOpen(false);
    }




    return (
        <div>
            <Modal
                open={isModalOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <FormControl variant="standard" sx={formControl}>
                        <Box display='flex' justifyContent='center'>
                            <Typography variant='h5'>Add a new product</Typography>
                        </Box>
                        <TextField label="Product Name" variant="filled" sx={inputField} onChange={handleNameChange} required />
                        <TextField label="Wholesale Price (optional)" variant="filled" sx={inputField} onChange={handleWholesalePriceChange} />
                        <TextField label="Currency (optional)" variant="filled" sx={inputField} onChange={handleWholesalePriceCurrencyChange} />
                        <TextField label="SKU (optional)" variant="filled" sx={inputField} onChange={handleSkuChange} />
                        <TextField label="Category (optional)" variant="filled" sx={inputField} onChange={handleCategoryChange} />
                        <TextField label="Sub-Category (optional)" variant="filled" sx={inputField} onChange={handleSubCategoryChange} />
                        <TextField label="Unit (optional)" variant="filled" sx={inputField} onChange={handleUnitChange} />
                        <TextField label="Size (optional)" variant="filled" sx={inputField} onChange={handleSizeChange} />
                        <TextField label="Color (optional)" variant="filled" sx={inputField} onChange={handleColorChange} />
                        <Box mb={2}>
                            <Typography variant="h6">Upload Images (optional)</Typography>
                            <input
                                accept="image/*"
                                style={{ display: 'none' }}
                                id="image-upload"
                                type="file"
                                multiple
                                onChange={handleImageChange}
                            />
                            <label htmlFor="image-upload">
                                <Button variant="contained" component="span" fullWidth>Upload Images</Button>
                            </label>
                        </Box>
                        {selectedImages.length > 0 && (
                            <Box maxWidth="md" mb={2}>
                                <Grid container spacing={2}>
                                    {selectedImages.map((image, index) => (
                                        <Grid item key={index} xs={12} sm={6} md={4}>
                                            <Card>
                                                <CardMedia
                                                    component="img"
                                                    alt={`Uploaded Image ${index + 1}`}
                                                    height="200"
                                                    image={image}
                                                />
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        )}
                        <Box display='flex' justifyContent='space-between'>
                            <Button variant="outlined" onClick={handleButtonClose} sx={cancelSubmitButtons}>Cancel</Button>
                            <Button variant="contained" onClick={handleButtonSubmit} sx={cancelSubmitButtons}>Submit</Button>
                        </Box>
                    </FormControl>
                </Box>
            </Modal>
        </div>
    );
}

export default ProductFormModal;
