import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { baseUrl } from '../../utils/url-config';

export interface User {
    CLOUDID: string;
    CREATEDBY: string;
    EMAIL: string;
    ENDDATE: string;
    ID: string;
    NAME: string;
    PHONE: string;
    STARTDATE: string;
    UPDATEDBY: string;
    USERTYPESTARTDATE: string;
    USERTYPEID: number;
}

export interface UserState {
    users: User[];
    currentUser: any;
    loading: boolean;
    error: string | null;
}

// interface UserPayload {
//     CLOUDID: string;
//     USERTYPEID: number;
//     NAME: string | null;
//     EMAIL: string | null;
//     PHONE: string;
//     STARTDATE: string;
//     USERTYPESTARTDATE: string;
//     CREATEDBY: string | null;
//     UPDATEDBY: string | null;
// }

interface AssignUserTypePayload {
    userId: number;
    userTypeId: number;
}

const initialState: UserState = {
    users: [],
    currentUser: {},
    loading: false,
    error: null
};

// We are just testing it, but this api should only be avaialbe for admin user
export const fetchAllUsers = createAsyncThunk<User[], void, { rejectValue: Error }>(
    'user/fetchAllUsers/get',
    async (_, thunkAPI) => {
        try {
            const response = await axios.get<User[]>(`${baseUrl}/users`);
            return response.data;
        } catch (error: unknown) {
            return thunkAPI.rejectWithValue(error as Error);
        }
    }
);

export const fetchUidUserInfo = createAsyncThunk<User, string, { rejectValue: Error }>(
    'user/fetchUidUserInfo/get',
    async (uid, thunkAPI) => {
        try {
            const response = await axios.get<User>(`${baseUrl}/users/uid/${uid}`);
            return response.data;
        } catch (error: unknown) {
            return thunkAPI.rejectWithValue(error as Error);
        }
    }
);

export const assignUserType = createAsyncThunk<
    User,
    AssignUserTypePayload,
    { rejectValue: Error }
>(
    'user/assignUserType/patch',
    async (payload, thunkAPI) => {
        try {
            const response = await axios.patch<User>(`${baseUrl}/users/id/${payload.userId}/user-type`, payload);
            return response.data;
        } catch (error: unknown) {
            return thunkAPI.rejectWithValue(error as Error);
        }
    }
);

export const createNewUser = createAsyncThunk<
    User,
    any,
    { rejectValue: Error }
>(
    'user/createNewUser/post',
    async (payload, thunkAPI) => {
        try {
            const response = await axios.post<User>(`${baseUrl}/users`, payload);
            return response.data;
        } catch (error: unknown) {
            return thunkAPI.rejectWithValue(error as Error);
        }
    }
);

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllUsers.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchAllUsers.fulfilled, (state, action: PayloadAction<User[]>) => {
                state.loading = false;
                state.users = action.payload;
            })
            .addCase(fetchAllUsers.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload;
            }).addCase(fetchUidUserInfo.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchUidUserInfo.fulfilled, (state, action: PayloadAction<User>) => {
                state.loading = false;
                state.currentUser = action.payload;
            })
            .addCase(fetchUidUserInfo.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default usersSlice.reducer;
