import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { baseUrl } from '../../utils/url-config';
import { User } from "../users";
import { getUserInfo } from "../../utils/authentication";
import { axiosInstance } from '../../utils/axios-instance';
import { ICProject } from '../projects';


export interface FieldAgentICProject {
    [x: string]: any;
    ID: number;
    ICPROJECTID: number;
    FIELDAGENTID: number;
    STATUSID: number;
    APPLIEDDATETIME: Date;
    DISCONTINUEDDATETIME: Date;
    CREATEDBY: string;
    UPDATEDBY: string;
    ICProject: ICProject;
    User: User;
}

export interface FieldAgentICProjectState {
    allICProjectsByAgent: FieldAgentICProject[]; //TODO rename this field to allICProjectsByOwner
    currentICProjectByAgent: any;
    loading: boolean;
    error: string | null;
}

const initialState: FieldAgentICProjectState = {
    allICProjectsByAgent: [],
    currentICProjectByAgent: {},
    loading: false,
    error: null
};

/**
 * Return all the IC Projects for the current agent
 */
export const fetchAllStoreICProjectsByAgent = createAsyncThunk<FieldAgentICProject[], void, { rejectValue: Error }>(
    'store/fetchAllStoreICProjectsByAgent/get',
    async (_, thunkAPI) => {
        try {
            const userInfo: User = getUserInfo();
            const response = await axiosInstance.get<FieldAgentICProject[]>(`${baseUrl}/field-agent-ic-projects/store-project/user/${userInfo.ID}`);
            return response.data;
        } catch (error: unknown) {
            return thunkAPI.rejectWithValue(error as Error);
        }
    }
);

const agentICProjectsSlice = createSlice({
    name: 'agenticprojects',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllStoreICProjectsByAgent.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchAllStoreICProjectsByAgent.fulfilled, (state, action: PayloadAction<FieldAgentICProject[]>) => {
                state.loading = false;
                state.allICProjectsByAgent = action.payload;
            })
            .addCase(fetchAllStoreICProjectsByAgent.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default agentICProjectsSlice.reducer;
