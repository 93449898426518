import { Box, Tab, Tabs, useMediaQuery } from "@mui/material";
import InitialPageSiteHeader from "../../components/siteHeader/initialPageSiteHeader";
import React from "react";
import StoreShelfStocking from "./storeShelfStocking";
import FarmingLabor from "./farmingLabor";

export default function InitialPage() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: any, newValue: React.SetStateAction<number>) => {
        setValue(newValue);
    };

    const isMobile = useMediaQuery('(max-width: 600px)');

    return (
        <>
            <Box display={'flex'} flexDirection={'column'}>
                <InitialPageSiteHeader pageTitle='Initial Page' />
                <Box display={'flex'} flexDirection={isMobile ? 'column' : 'row'} marginTop={'10rem'}>
                    {isMobile ? <Box marginBottom={'2rem'}>
                        <Tabs
                            orientation="horizontal"
                            value={value}
                            onChange={handleChange}
                            aria-label="Vertical tabs"
                        >
                            <Tab label="Store Shelf Stocking" />
                            <Tab label="Farming Labor" />
                        </Tabs>
                    </Box> : <Box flexGrow={1}>
                        <Tabs
                            orientation="vertical"
                            value={value}
                            onChange={handleChange}
                            aria-label="Vertical tabs"
                        >
                            <Tab label="Store Shelf Stocking" />
                            <Tab label="Farming Labor" />
                        </Tabs>
                    </Box>}
                    <Box width={isMobile ? '100%' : '70%'}>
                        {value === 0 && <StoreShelfStocking />}
                        {value === 1 && <FarmingLabor />}
                    </Box>
                </Box>

            </Box>
        </>
    )
}
