import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { PortalCardProps } from './portalCard.interface';
import { Link } from "react-router-dom";
import styled from '@emotion/styled';

const StyledLink = styled(Link)({
    textDecoration: 'none',
    color: 'black'
});

const CardContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    gap: '1rem',
    marginTop: '1rem',
});

const StyledCard = styled(Card)({
    width: '300px', // Initial width for a single card
    minWidth: '200px', // Minimum width for responsiveness
    maxWidth: '100%', // Maximum width to occupy full width in smaller screens
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
        transform: 'scale(1.05)',
    },
    '@media (min-width: 600px)': {
        width: '400px', // Adjust width for larger screens (PC)
    },
});

const StyledCardContent = styled(CardContent)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '2rem', // Add padding for content
    textAlign: 'center',
    backgroundColor: '#f5f5f5', // Background color for the card content
    borderRadius: '12px', // Border radius for a rounded look
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Add box shadow for depth
});

export default function PortalCard({ portalCard }: PortalCardProps) {
    return (
        <StyledLink to={portalCard.navigateTo} onClick={portalCard.onClick ? portalCard.onClick : null}>
            <CardContainer>
                <StyledCard className='portal-card'>
                    <StyledCardContent>
                        <Box sx={{ fontSize: '3rem', margin: 'auto' }} component={portalCard.displaySvg} />
                        <Typography variant="h6" fontWeight='bold' sx={{ marginTop: '1rem' }}>
                            {portalCard.displayInfo}
                        </Typography>
                    </StyledCardContent>
                </StyledCard>
            </CardContainer>
        </StyledLink>
    );
}