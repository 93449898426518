import IcOwnerProjectCardList from './icOwnerProjectCardList';


function IcOwnerProjectDataTable({ rows, setIsProjectModalOpen, setIsReviewTransactionModalOpen, setIsTransactionHistoryModalOpen }: any) {

    return (
        <div style={{ height: 400, width: '50%' }}>
            <IcOwnerProjectCardList rows={rows} setIsProjectModalOpen={setIsProjectModalOpen} setIsReviewTransactionModalOpen={setIsReviewTransactionModalOpen} setIsTransactionHistoryModalOpen={setIsTransactionHistoryModalOpen} />
        </div>
    );
}

export default IcOwnerProjectDataTable;
