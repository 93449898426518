import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Grid, Menu, MenuItem, Pagination } from '@mui/material';
import { useDispatch } from 'react-redux';
import { clearSelectedProject, setSelectedProject } from '../../../store/myProjectSlice';
import { useEffect } from 'react';
import { setSelectedTask } from '../../../store/myTaskSlice';

interface RowData {
    fieldAgentICProject: RowData | null;
    hasPendingReviewTransaction: any;
    id: number;
    project: string;
    reviewCycle: string;
    description: string;
    startDate: Date;
    endDate: Date;
    earningType: string;
    earningValue: number;
    img: string;
}

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const IcOwnerProjectCardList: React.FC<any> = ({ rows, setIsProjectModalOpen, setIsReviewTransactionModalOpen, setIsTransactionHistoryModalOpen }: any) => {
    const [expandedId, setExpandedId] = React.useState<number | null>(null);
    const [page, setPage] = React.useState(1);
    const itemsPerPage = 9;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [currentMenuRow, setCurrentMenuRow] = React.useState<RowData | null>(null);
    const dispatch = useDispatch();

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, row: RowData) => {
        setAnchorEl(event.currentTarget);
        setCurrentMenuRow(row);
    };

    const onUpdateProjectClick = () => {
        setIsProjectModalOpen(true);
        dispatch(setSelectedProject(currentMenuRow));
    }

    useEffect(() => {
        return () => {
            clearSelectedProject();
        }
    }, []);

    const onReviewTransactionClick = () => {
        setIsReviewTransactionModalOpen(true);
        dispatch(setSelectedTask(currentMenuRow && currentMenuRow.fieldAgentICProject ? currentMenuRow.fieldAgentICProject : {}));
    }

    const onTransactionHistoryClick = () => {
        setIsTransactionHistoryModalOpen(true);
        dispatch(setSelectedTask(currentMenuRow && currentMenuRow.fieldAgentICProject ? currentMenuRow.fieldAgentICProject : {}));
    }

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleExpandClick = (id: number) => {
        setExpandedId(id === expandedId ? null : id);
    };


    const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
        setPage(newPage);
    };

    const paginatedRows = rows.slice((page - 1) * itemsPerPage, page * itemsPerPage);


    return (
        <React.Fragment>
            <Grid container spacing={2}>
                {paginatedRows.map((row: RowData) => (
                    <Grid item key={row.id} xs={12} sm={6} md={4}>
                        <Card sx={{ marginBottom: '20px' }} style={{ backgroundColor: row.hasPendingReviewTransaction ? '#f5d5a9' : '' }}>
                            <CardHeader
                                action={
                                    <>
                                        <IconButton aria-label="settings" onClick={(e) => handleMenuOpen(e, row)}>
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            id="action-menu"
                                            anchorEl={anchorEl}
                                            open={Boolean(anchorEl)}
                                            onClose={handleMenuClose}
                                        >
                                            <MenuItem onClick={() => { handleMenuClose(); onUpdateProjectClick(); }}>Update this project</MenuItem>
                                            <MenuItem onClick={() => { handleMenuClose(); onReviewTransactionClick(); }}>Review Transaction</MenuItem>
                                            <MenuItem onClick={() => { handleMenuClose(); onTransactionHistoryClick(); }}>Transaction History</MenuItem>
                                        </Menu>
                                    </>
                                }
                                title={row.project}
                                subheader={`Start Date ${row.startDate} End Date ${row.endDate}`}
                            />
                            <CardMedia
                                component="img"
                                height="194"
                                image={row.img}
                                alt={row.project}
                                sx={{ objectFit: 'contain' }}
                            />
                            <CardContent>
                                <Typography variant="body2" color="text.secondary">
                                    Earning: {row.earningValue}, {row.earningType}, {row.reviewCycle} plan
                                    cycle
                                </Typography>
                            </CardContent>
                            <CardActions disableSpacing>
                                <ExpandMore
                                    onClick={() => handleExpandClick(row.id)}
                                    aria-expanded={expandedId === row.id}
                                    aria-label="show more"
                                    expand={expandedId === row.id}
                                >
                                    <ExpandMoreIcon />
                                </ExpandMore>
                            </CardActions>
                            <Collapse in={expandedId === row.id} timeout="auto">
                                <CardContent>
                                    <Typography paragraph>Earning: {row.earningValue}</Typography>
                                    <Typography paragraph>
                                        {row.project} is between {String(row.startDate)} and {String(row.endDate)}.
                                        It follows a {row.reviewCycle} plan
                                        cycle.
                                    </Typography>
                                    <Typography>
                                        The image shows: {row.project}
                                    </Typography>
                                </CardContent>
                            </Collapse>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <Pagination
                count={Math.ceil(rows.length / itemsPerPage)}
                page={page}
                onChange={handleChangePage}
                color="primary"
                style={{ marginTop: '20px', justifyContent: 'center', display: 'flex' }}
            />
        </React.Fragment>
    );
};

export default IcOwnerProjectCardList;