import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { baseUrl } from '../../utils/url-config';
import { axiosInstance } from '../../utils/axios-instance';


export interface StoreICProjectTransactionWorkflow {
    ID: number;
    FIELDAGENTSTOREICPROJECTTRANSACTIONID: number;
    EARNINGSTATUSID: number;
    WORKFLOWOPERATORID: number;
    CREATEDBY: string;
    UPDATEDBY: string;
    CREATEDAT: Date;
    UPDATEDAT: Date;
}

export interface StoreICProjectTransactionWorkflowState {
    allWorkflowsByTransaction: StoreICProjectTransactionWorkflow[];
    currentWorkflowByTransaction: any;
    loading: boolean;
    error: string | null;
}

const initialState: StoreICProjectTransactionWorkflowState = {
    allWorkflowsByTransaction: [],
    currentWorkflowByTransaction: {},
    loading: false,
    error: null
};

/**
 * Expected payload to create a new item in STOREPROJECTTRANSACTIONWORKFLOW table
 */
interface updateStoreICProjectTransactionWorkflowPayload {
    FIELDAGENTSTOREICPROJECTTRANSACTIONID: number;
    EARNINGSTATUSID: number;
    userId: number; //OPERATOR ID
}

/**
 * Return all the workflow items for the specific transaction by the transaction ID
 */
export const fetchAllWorkflowsByTransaction = createAsyncThunk<StoreICProjectTransactionWorkflow[], number, { rejectValue: Error }>(
    'store/fetchAllWorkflowsByTransaction/get',
    async (transactionId, thunkAPI) => {
        try {
            const response = await axiosInstance.get<StoreICProjectTransactionWorkflow[]>(`${baseUrl}/store-ic-project-transaction-workflow/transactionId/${transactionId}`);
            return response.data;
        } catch (error: unknown) {
            return thunkAPI.rejectWithValue(error as Error);
        }
    }
);

/**
 * Find the workflow by its ID
 */
export const fetchWorkflowById = createAsyncThunk<StoreICProjectTransactionWorkflow, void, { rejectValue: Error }>(
    'store/fetchWorkflowById/get',
    async (id, thunkAPI) => {
        try {
            const response = await axiosInstance.get<StoreICProjectTransactionWorkflow>(`${baseUrl}/store-ic-project-transaction-workflow/${id}`);
            return response.data;
        } catch (error: unknown) {
            return thunkAPI.rejectWithValue(error as Error);
        }
    }
);

/**
 * Create a new workflow item in STOREPROJECTTRANSACTIONWORKFLOW table
 */
export const createStoreICProjectTransactionWorkflowInfo = createAsyncThunk<StoreICProjectTransactionWorkflow, updateStoreICProjectTransactionWorkflowPayload, { rejectValue: Error }>(
    'store/createStoreICProjectTransactionWorkflowInfo/post',
    async (payload, thunkAPI) => {
        try {
            const response = await axiosInstance.post<StoreICProjectTransactionWorkflow>(`${baseUrl}/store-ic-project-transaction-workflow/create/user/${payload.userId}`, payload);
            return response.data;
        } catch (error: unknown) {
            return thunkAPI.rejectWithValue(error as Error);
        }
    }
);


const agentICProjectTransactionsSlice = createSlice({
    name: 'agenticprojecttransactions',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllWorkflowsByTransaction.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchAllWorkflowsByTransaction.fulfilled, (state, action: PayloadAction<StoreICProjectTransactionWorkflow[]>) => {
                state.loading = false;
                state.allWorkflowsByTransaction = action.payload;
            })
            .addCase(fetchAllWorkflowsByTransaction.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload;
            }).addCase(fetchWorkflowById.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchWorkflowById.fulfilled, (state, action: PayloadAction<StoreICProjectTransactionWorkflow>) => {
                state.loading = false;
                state.currentWorkflowByTransaction = action.payload;
            })
            .addCase(fetchWorkflowById.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default agentICProjectTransactionsSlice.reducer;
