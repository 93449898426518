import React from 'react';
import { Box, Typography, Divider } from '@mui/material';
import SiteHeader from '../../components/siteHeader/siteHeader';

const WhyMyOpenIC = () => {
    const pageStyle = {
        backgroundColor: '#f5f5f5',
        padding: '50px',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        color: '#333',
        marginTop: '5rem'
    };

    const titleStyle = {
        color: '#333',
        fontWeight: 'bold',
        marginBottom: '20px',
        textAlign: 'center',
    };

    const reasonContainerStyle = {
        margin: '30px 0',
    };

    const reasonTitleStyle = {
        color: '#444',
        fontWeight: 'bold',
        marginBottom: '24px',
    };

    const reasonContentStyle = {
        color: '#666',
    };

    return (
        <Box>
            <SiteHeader pageTitle='Why MyOpenIC?' />
            <Box sx={pageStyle}>
                <Typography variant="h4" sx={titleStyle} gutterBottom>
                    Why MyOpenIC?
                </Typography>
                <Divider variant="middle" />
                <Box sx={{ mt: 4 }}>
                    <Box sx={reasonContainerStyle}>
                        <Typography variant="h6" sx={reasonTitleStyle}>
                            IC Owner
                        </Typography>
                        <Typography variant="body1" sx={reasonContentStyle}>
                            <strong>Tired of juggling inventory control tasks? </strong>
                            myOpenIC is here to help! Whether you manage supermarket shelves, warehouses, or unique business inventories,
                            our platform streamlines the process.
                        </Typography>
                        <Typography variant="body1" sx={reasonContentStyle}>
                            <strong>Here's how you benefit:</strong>
                            <ul>
                                <li><strong>Effortless Project Creation: </strong>Create customized inventory control projects in minutes, specifying your unique needs and instructions.</li>
                                <li><strong>Reach the Right Talent: </strong>Publish your project to a pool of qualified field agents and planners, ready to execute your vision.</li>
                                <li><strong>Streamlined Workflow: </strong>Manage applications, track progress, and receive clear reports – all within our user-friendly platform.</li>
                                <li><strong>Transparent Payment: </strong>Ensure timely payments to your chosen agent or planner through our secure payment system.</li>
                            </ul>
                            <strong>Join myOpenIC and reclaim control of your inventory, while empowering your team to succeed!</strong>
                        </Typography>
                    </Box>
                    <Box sx={reasonContainerStyle}>
                        <Typography variant="h6" sx={reasonTitleStyle}>
                            IC Field Agent
                        </Typography>
                        <Typography variant="body1" sx={reasonContentStyle}>
                            <strong>Looking for flexible work opportunities with competitive pay? </strong>
                            myOpenIC connects you with diverse inventory control projects across various industries.
                        </Typography>
                        <Typography variant="body1" sx={reasonContentStyle}>
                            <strong>Here's what awaits you:</strong>
                            <ul>
                                <li><strong>Find Projects You Love: </strong>Browse a wide range of projects based on your skills, location, and availability.</li>
                                <li><strong>Clear Instructions: </strong>Get detailed instructions from IC owners, ensuring you complete tasks accurately and efficiently.</li>
                                <li><strong>Flexible Schedule: </strong>Choose projects that fit your lifestyle and work at your own pace.</li>
                                <li><strong>Secure Payment: </strong>Receive prompt and secure compensation directly through myOpenIC after completing each project.</li>
                            </ul>
                            <strong>Become an myOpenIC Field Agent and turn your expertise into earning potential!</strong>

                        </Typography>
                    </Box>
                    <Box sx={reasonContainerStyle}>
                        <Typography variant="h6" sx={reasonTitleStyle}>
                            Inventory Planner
                        </Typography>
                        <Typography variant="body1" sx={reasonContentStyle}>
                            <strong>Looking for easy analysis opportunities in front of a screen at home?</strong>
                            myOpenIC provides inventory report projects for you.
                        </Typography>
                        <Typography variant="body1" sx={reasonContentStyle}>
                            <strong>Here’s what awaits you:</strong>
                            <ul>
                                <li><strong>Easy and Comfortable: </strong>you don’t need to leave your home, just look at the pictures and finish the excel reports.</li>
                                <li><strong>Simple Task: </strong>myOpenIC just needs your help to figure out stock levels.</li>
                                <li><strong>Flexible Schedule: </strong>No schedule required, you can make some extra money during your sleepless nights.</li>
                                <li><strong>Secure Payment: </strong>Receive prompt and secure compensation directly through myOpenIC after completing each project.</li>
                            </ul>
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default WhyMyOpenIC;
