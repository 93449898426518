import { Button } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useDispatch } from 'react-redux';
import { setSelectedTask } from '../../store/myTaskSlice';

const rows = [
    { id: 1, store: 'Safeway1', product: 'test product12345', cycle: 'Weekly', lastUpdated: '12/1/2023', nextDue: '12/10/2023', earning: '$12' },
    { id: 2, store: 'Safeway2', product: 'test product354', cycle: 'Weekly', lastUpdated: '12/1/2023', nextDue: '12/10/2023', earning: '$122' },
    { id: 3, store: 'Safeway3', product: 'test product2345', cycle: 'Weekly', lastUpdated: '12/1/2023', nextDue: '12/10/2023', earning: '$112' },
    { id: 4, store: 'Safeway4', product: 'test product23453', cycle: 'Weekly', lastUpdated: '12/1/2023', nextDue: '12/10/2023', earning: '$152' },
    { id: 5, store: 'Safeway5', product: 'test product2345', cycle: 'Weekly', lastUpdated: '12/1/2023', nextDue: '12/10/2023', earning: '$162' },
    { id: 6, store: 'Safeway6', product: 'test product34543', cycle: 'Weekly', lastUpdated: '12/1/2023', nextDue: '12/10/2023', earning: '$712' },
    { id: 7, store: 'Safeway7', product: 'test product63245', cycle: 'Weekly', lastUpdated: '12/1/2023', nextDue: '12/10/2023', earning: '$182' },
    { id: 8, store: 'Safeway8', product: 'test product532435', cycle: 'Weekly', lastUpdated: '12/1/2023', nextDue: '12/10/2023', earning: '$912' }
];
function StoreDataTable({ setIsTaskHistoryModalOpen, setIsUpdateaskModalOpen }: any) {
    const dispatch = useDispatch();
    const renderUpdateButton = (params: any) => {
        return (
            <strong>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ marginLeft: 16 }}
                    onClick={() => {
                        dispatch(setSelectedTask(params.row));
                        console.log(params.row);
                        setIsUpdateaskModalOpen(true);
                    }}
                >
                    Update
                </Button>
            </strong>
        )
    }

    const renderHistoryButton = (params: any) => {
        return (
            <strong>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ marginLeft: 16 }}
                    onClick={() => {
                        dispatch(setSelectedTask(params.row));
                        console.log(params.row);
                        setIsTaskHistoryModalOpen(true);
                    }}
                >
                    History
                </Button>
            </strong>
        )
    }

    const columns: GridColDef[] = [
        { field: 'store', headerName: 'Store Name', width: 100 },
        { field: 'product', headerName: 'Product', width: 200 },
        { field: 'cycle', headerName: 'Cycle', width: 100 },
        { field: 'lastUpdated', headerName: 'Last Update Date', width: 100 },
        { field: 'nextDue', headerName: 'Next Due Date', width: 100 },
        { field: 'earning', headerName: 'Earning', width: 100 },
        { field: 'update', headerName: 'Update', width: 100, sortable: false, renderCell: renderUpdateButton },
        { field: 'history', headerName: 'History', width: 100, sortable: false, renderCell: renderHistoryButton }
    ];

    return (
        <div style={{ height: 400, width: '50%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                    },
                }}
                pageSizeOptions={[5, 10]}
            />
        </div>
    );
}

export default StoreDataTable;
