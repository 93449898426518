import { Box, Button, Card, CardMedia, FormControl, Grid, Modal, TextField, Typography } from "@mui/material";
import { useState, ChangeEvent, MouseEvent, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo, storage } from "../../../utils/authentication";
import { ref, uploadBytes } from "firebase/storage";
import { fetchAllProducts, updateProduct } from "../../../redux/products";
import { AppDispatch } from "../../../store";
import { User } from "../../../redux/users";
import { v4 as uuidv4 } from 'uuid';

const style: React.CSSProperties = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: '600px',
    background: 'white',
    border: '2px solid grey',
    borderRadius: '8px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    padding: 4,
    overflowY: 'auto',
    maxHeight: '90vh'
};

const inputField = {
    marginTop: '5%'
}

const formControl: React.CSSProperties = {
    width: '100%',
};

const cancelSubmitButtons = {
    marginTop: '5%',
    width: '40%'
}


const UpdateProductModal = ({ isModalOpen, setIsModalOpen }: any) => {
    const userInfo: User = getUserInfo();
    const dispatch = useDispatch<AppDispatch>();
    const [productName, setProductName] = useState('');
    const [productDescription, setProductDescription] = useState('');
    const [productBrand, setProductBrand] = useState('');
    const [productCategory, setProductCategory] = useState('');
    const [productSubCategory, setProductSubCategory] = useState('');
    const [productWholesalePrice, setProductWholesalePrice] = useState('');
    const [productWholesalePriceCurrency, setProductWholesalePriceCurrency] = useState('');
    const [productUnit, setProductUnit] = useState('');
    const [productSize, setProductSize] = useState('');
    const [productColor, setProductColor] = useState('');
    const [productSku, setProductSku] = useState('');
    const [selectedImageFiles, setSelectedImageFiles] = useState<File[]>([]);
    const [selectedImages, setSelectedImages] = useState<string[]>([]);

    const handleClose = (event: MouseEvent<HTMLElement>, reason: string) => {
        event.preventDefault();
        if (reason === 'backdropClick') {
            return;
        }
        setIsModalOpen(false);
    };
    const handleButtonClose = () => setIsModalOpen(false);
    const rowData = useSelector((state: any) => state.myProduct.selectedProduct);
    useEffect(() => {
        setProductName(rowData.name);
        setProductDescription(rowData.description);
        setProductBrand(rowData.brand);
        setProductCategory(rowData.category);
        setProductSubCategory(rowData.subCategory);
        setProductWholesalePrice(rowData.wholesalePrice);
        setProductWholesalePriceCurrency(rowData.wholesalePriceCurrency);
        setProductUnit(rowData.unit);
        setProductSize(rowData.size);
        setProductColor(rowData.color);
        setProductSku(rowData.sku);
        setSelectedImages(rowData.productUrls ? rowData.productUrls : []);
    }, [rowData])

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const productName = event.target.value;
        setProductName(productName);
    };

    const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const productDescription = event.target.value;
        setProductDescription(productDescription);
    };

    const handleBrandChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const productBrand = event.target.value;
        setProductBrand(productBrand);
    }

    const handleCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const productCategory = event.target.value;
        setProductCategory(productCategory);
    }

    const handleSubCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const productSubCategory = event.target.value;
        setProductSubCategory(productSubCategory);
    }

    const handleWholesalePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const productWholesalePrice = event.target.value;
        setProductWholesalePrice(productWholesalePrice);
    }

    const handleWholesalePriceCurrencyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        //const productWholesalePriceCurrency = event.target.value;
        const productWholesalePriceCurrency = "USD";
        setProductWholesalePriceCurrency(productWholesalePriceCurrency);
    }

    const handleUnitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const productUnit = event.target.value;
        setProductUnit(productUnit);
    }

    const handleSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const productSize = event.target.value;
        setProductSize(productSize);
    }

    const handleColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const productColor = event.target.value;
        setProductColor(productColor);
    }

    const handleSkuChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const sku = event.target.value;
        setProductSku(sku);
    }

    const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            setSelectedImageFiles(Array.from(files));
            const imagesArray: string[] = [];
            for (let i = 0; i < files.length; i++) {
                const reader = new FileReader();
                reader.onload = (e: ProgressEvent<FileReader>) => {
                    if (e.target && e.target.result) {
                        imagesArray.push(e.target.result as string);
                        if (imagesArray.length === files.length) {
                            setSelectedImages(imagesArray);
                        }
                    }
                };
                reader.readAsDataURL(files[i]);
            }
        }
    };

    const uploadFile = (imageUpload: File, imagePath: string) => {
        if (!imageUpload) {
            return
        }
        const imageRef = ref(storage, imagePath);
        return uploadBytes(imageRef, imageUpload).then((snapshot) => {
            console.log('successfully uploaded');
            console.log(imageRef);
            console.log(snapshot);
        });
    };

    const handleButtonSubmit = async () => {
        const ownerId = userInfo.ID;
        const imagePath = selectedImageFiles.map(selectedImage => `${userInfo.CLOUDID}/product/${selectedImage.name + uuidv4()}`);
        const payload = {
            ID: rowData.id,
            OWNERID: Number(ownerId),
            NAME: productName,
            DESCRIPTION: productDescription,
            BRAND: productBrand,
            CATEGORY: productCategory,
            SUBCATEGORY: productSubCategory,
            WHOLESALEPRICE: Number(productWholesalePrice),
            WHOLESALEPRICECURRENCY: productWholesalePriceCurrency ? productWholesalePriceCurrency : "USD",
            UNIT: productUnit,
            SIZE: productSize,
            SKU: productSku,
            COLOR: productColor,
            IMAGEPATHS: imagePath.length ? JSON.stringify(imagePath) : rowData.originalImgUrls,
            CREATEDBY: userInfo.NAME ? userInfo.NAME : userInfo.EMAIL,
            UPDATEDBY: userInfo.NAME ? userInfo.NAME : userInfo.EMAIL
        };

        await dispatch(updateProduct(payload as any));
        await Promise.all(
            selectedImageFiles.map(async (selectedImage, index) => {
                await uploadFile(selectedImage, imagePath[index]);
            })
        ).then(() => {
            console.log('all images uploaded');
            dispatch(fetchAllProducts());
        })
        setIsModalOpen(false);
    }

    return (
        <Box>
            <Modal
                open={isModalOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <FormControl variant="standard" sx={formControl}>
                        <Box display='flex' justifyContent='center'>
                            <Typography variant='h5'>Update a product</Typography>
                        </Box>
                        <TextField label="Product Name" variant="filled" sx={inputField} onChange={handleNameChange} value={productName} />
                        <TextField label="Product Description" variant="filled" sx={inputField} onChange={handleDescriptionChange} value={productDescription} />
                        <TextField label="Brand Name" variant="filled" sx={inputField} onChange={handleBrandChange} value={productBrand} />
                        <TextField label="Wholesale Price" variant="filled" sx={inputField} onChange={handleWholesalePriceChange} value={productWholesalePrice} />
                        <TextField label="Currency (optional)" variant="filled" sx={inputField} onChange={handleWholesalePriceCurrencyChange} value={productWholesalePriceCurrency} />
                        <TextField label="SKU (optional)" variant="filled" sx={inputField} onChange={handleSkuChange} value={productSku} />
                        <TextField label="Category (optional)" variant="filled" sx={inputField} onChange={handleCategoryChange} value={productCategory} />
                        <TextField label="Sub-Category (optional)" variant="filled" sx={inputField} onChange={handleSubCategoryChange} value={productSubCategory} />
                        <TextField label="Unit (optional)" variant="filled" sx={inputField} onChange={handleUnitChange} value={productUnit} />
                        <TextField label="Size (optional)" variant="filled" sx={inputField} onChange={handleSizeChange} value={productSize} />
                        <TextField label="Color (optional)" variant="filled" sx={inputField} onChange={handleColorChange} value={productColor} />
                        <Box mb={2}>
                            <Typography variant="h6">Upload Images (optional)</Typography>
                            <input
                                accept="image/*"
                                style={{ display: 'none' }}
                                id="image-upload"
                                type="file"
                                multiple
                                onChange={handleImageChange}
                            />
                            <label htmlFor="image-upload">
                                <Button variant="contained" component="span" fullWidth>Upload Images</Button>
                            </label>
                        </Box>
                        {selectedImages.length > 0 && (
                            <Box maxWidth="md" mb={2}>
                                <Grid container spacing={2}>
                                    {selectedImages.map((image, index) => (
                                        <Grid item key={index} xs={12} sm={6} md={4}>
                                            <Card>
                                                <CardMedia
                                                    component="img"
                                                    alt={`Uploaded Image ${index + 1}`}
                                                    height="200"
                                                    image={image}
                                                />
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        )}
                        <Box display='flex' justifyContent='space-between'>
                            <Button variant="outlined" onClick={handleButtonClose} sx={cancelSubmitButtons}>Cancel</Button>
                            <Button variant="contained" onClick={handleButtonSubmit} sx={cancelSubmitButtons}>Submit</Button>
                        </Box>
                    </FormControl>
                </Box>
            </Modal >
        </Box >)
}

export default UpdateProductModal;
