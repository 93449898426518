import * as React from 'react';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { auth, setExpirationToken } from '../../utils/authentication';
import { signInWithEmailAndPassword } from 'firebase/auth';
import {
    Button,
    TextField,
    Paper,
    Box,
    Grid,
    Typography,
    createTheme,
    ThemeProvider,
    styled,
    IconButton,
    InputAdornment
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import SiteHeader from '../../components/siteHeader/siteHeader';

const defaultTheme = createTheme();

const LoginContainer = styled(Grid)(({ theme }) => ({
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'linear-gradient(to right bottom, #4d6bca, #2a3a8e)',
    backgroundSize: 'cover',
}));


export default function SignInSide() {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const email = String(data.get('email'));
        const password = String(data.get('password'));

        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const user = userCredential.user as any;
                window.localStorage.setItem('accessToken', user.accessToken);
                window.localStorage.setItem('refreshToken', user.refreshToken);
                window.localStorage.setItem('uid', user.uid);
                setExpirationToken();
                navigate("/home");
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                switch (errorCode) {
                    case 'auth/invalid-email':
                        setErrorMessage('Invalid email address.');
                        break;
                    case 'auth/user-disabled':
                        setErrorMessage('Your account has been disabled.');
                        break;
                    case 'auth/user-not-found':
                        setErrorMessage('User not found.');
                        break;
                    case 'auth/invalid-credential':
                        setErrorMessage('Wrong email or password.');
                        break;
                    default:
                        setErrorMessage('Authentication failed.');
                        break;
                }
                console.log(errorCode);
                console.log(errorMessage);
            });
    };

    const togglePasswordVisibility = () => {
        setShowPassword((prevState) => !prevState);
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <SiteHeader pageTitle='Sign In' />
            <LoginContainer container sx={{
                minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center',
            }}>
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} sx={{ backgroundColor: '#E6E6E6' }}>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}
                    >
                        <Typography component="h1" variant="h5">
                            Sign in to myOpenIC
                        </Typography>
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3, width: '100%' }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                autoComplete="email"
                                variant="standard"
                                autoFocus
                            />
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <TextField
                                    required
                                    name="password"
                                    label="Password"
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    variant="standard"
                                    autoComplete="current-password"
                                    sx={{ flexGrow: 1 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={togglePasswordVisibility}
                                                    edge="end"
                                                    aria-label="toggle password visibility"
                                                >
                                                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>
                            {errorMessage && (
                                <Typography variant="body2" color="error" sx={{ mb: 2 }}>
                                    {errorMessage}
                                </Typography>
                            )}
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 2 }}
                            >
                                Sign In
                            </Button>
                            <Button
                                fullWidth
                                variant="contained"
                                sx={{ mt: 2, color: 'white', backgroundColor: 'grey' }}
                                component={RouterLink}
                                to="/sign-up"
                            >
                                Sign Up
                            </Button>
                            <Typography sx={{ mt: 3 }}>Questions? Email us at <a href="mailto:Support@myopenic.com">Support@myopenic.com</a></Typography>
                        </Box>
                    </Box>
                </Grid>
            </LoginContainer>
        </ThemeProvider>
    );
}