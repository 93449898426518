import { Box, Modal, Typography, Button, FormControl, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CardMedia, Card, Grid, TextField, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { FieldAgentEarningStatus } from '../../utils/constants';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { getUserInfo, storage } from '../../utils/authentication';
import { v4 as uuidv4 } from 'uuid';
import { AppDispatch } from '../../store';
import { updateFieldAgentICProjectTransactionInfo } from '../../redux/agentprojecttransactions';
import { fetchAllStoreICProjectsByAgent } from '../../redux/agentprojects';
import { toLocalTime } from '../../utils/to-local-time';


interface TaskHistoryModalProps {
    isModalOpen: boolean;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const style: React.CSSProperties = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: '800px',
    background: 'white',
    border: '2px solid #ccc',
    borderRadius: '8px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    padding: 5,
    overflowY: 'auto',
    maxHeight: '90vh'
};

const displayedText = {
    marginTop: '1.5rem'
}

const cancelButtonStyle: React.CSSProperties = {
    marginRight: '1rem',
};

const inputField = {
    marginTop: '5%'
}

const tableHeadCellStyle: React.CSSProperties = {
    fontWeight: 'bold',
};

const buttonContainerStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '2rem',
};

function TaskHistoryModal({ isModalOpen, setIsModalOpen }: TaskHistoryModalProps) {
    const userInfo = getUserInfo();
    const selectedTask = useSelector((state: any) => state.myTask.selectedTask);
    const selectedHistory = selectedTask.transactionHistory ? selectedTask.transactionHistory : [];
    const [isUpdateOpen, setIsUpdateOpen] = useState(false);
    const [selectedHistoryItem, setSelectedHistoryItem] = useState<any>({});
    const [selectedImageFiles, setSelectedImageFiles] = useState<File[]>([]);
    const [conutOnShelf, setCountOnShelf] = useState<number>(0);
    const [estimateOnShelf, setEstimateOnShelf] = useState<number>(0);
    const [selectedImages, setSelectedImages] = useState<string[]>([]);

    const dispatch = useDispatch<AppDispatch>();

    console.log(selectedHistoryItem);

    const handleClose = (event: React.MouseEvent<HTMLElement>, reason: string) => {
        event.preventDefault();
        if (reason === 'backdropClick') {
            return;
        }
        setIsModalOpen(false);
    };

    const handleButtonSubmit = () => {
        setIsModalOpen(false);
    };


    const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            setSelectedImageFiles(Array.from(files));
            const imagesArray: string[] = [];
            for (let i = 0; i < files.length; i++) {
                const reader = new FileReader();
                reader.onload = (e: ProgressEvent<FileReader>) => {
                    if (e.target && e.target.result) {
                        imagesArray.push(e.target.result as string);
                        if (imagesArray.length === files.length) {
                            setSelectedImages(imagesArray);
                        }
                    }
                };
                reader.readAsDataURL(files[i]);
            }
        }
    };

    const handleImages = async (historyItem: any) => {
        if (historyItem.PICTURESPATH && historyItem.PICTURESPATH.length) {
            const urls: React.SetStateAction<string[]> = [];
            await Promise.all(
                JSON.parse(historyItem.PICTURESPATH).map(async (productImagePath: string | undefined) => {
                    const imageRef = ref(storage, productImagePath);
                    const url = await getDownloadURL(imageRef);
                    urls.push(url);
                })
            );
            setSelectedImages(urls);
        } else {
            setSelectedImages([]);
        }
        setIsUpdateOpen(true);
    }

    const uploadFile = (imageUpload: File, imagePath: string) => {
        if (!imageUpload) {
            return
        }
        const imageRef = ref(storage, imagePath);
        uploadBytes(imageRef, imageUpload).then((snapshot) => {
            console.log('successfully uploaded');
            console.log(imageRef);
            console.log(snapshot);
        });
    };

    const handleUpdateReviewSubmit = async () => {
        const imagePath = selectedImageFiles.map(selectedImage => `${userInfo.CLOUDID}/field-agent-ic-project/${selectedImage.name + uuidv4()}`);

        const payload = {
            PICTURESPATH: JSON.stringify(imagePath),
            ESTIMATEDCOUNTONSHELF: conutOnShelf,
            ESTIMATECOUNTONSHELF: estimateOnShelf,
            UPDATEDBY: userInfo.NAME ? userInfo.NAME : userInfo.EMAIL,
            FIELDAGENTPROJECTTRANSACTIONID: selectedHistoryItem.ID,
        }

        await dispatch(updateFieldAgentICProjectTransactionInfo(payload)).then(async () => {
            await Promise.all(selectedImageFiles.map(async (selectedImage, index) => {
                await uploadFile(selectedImage, imagePath[index]);
            }));
        });

        setIsUpdateOpen(false);
        setIsModalOpen(false);
        dispatch(fetchAllStoreICProjectsByAgent());
    }


    const handleUpdateReviewClose = () => {
        setIsUpdateOpen(false);
    }

    useEffect(() => {
        setCountOnShelf(selectedHistoryItem.ESTIMATEDCOUNTONSHELF);
        setEstimateOnShelf(Number(selectedHistoryItem.ESTIMATECOUNTONSHELF));
    }, [selectedHistoryItem, selectedHistoryItem.ESTIMATECOUNTONSHELF, selectedHistoryItem.ESTIMATEDCOUNTONSHELF])

    return (
        <>
            <Modal
                open={isModalOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <FormControl variant="standard">
                        <Box display='flex' justifyContent='center' margin='2rem'>
                            <Typography variant='h5'>Task History</Typography>
                        </Box>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={tableHeadCellStyle}>Store Visit Date/TIME</TableCell>
                                        <TableCell style={tableHeadCellStyle}>Earning</TableCell>
                                        <TableCell style={tableHeadCellStyle}>Payment Status</TableCell>
                                        <TableCell style={tableHeadCellStyle}>Supplier Rating</TableCell>
                                        <TableCell style={tableHeadCellStyle}>Supplier Comment</TableCell>
                                        <TableCell style={tableHeadCellStyle}>Update Review</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {selectedHistory.map((historyItem: any, index: number) => (
                                        <TableRow key={index}>
                                            <TableCell>{toLocalTime(historyItem.createdAt)}</TableCell>
                                            <TableCell>{historyItem.EARNINGVALUE} {historyItem.EARNINGCURRENCY}</TableCell>
                                            <TableCell>{FieldAgentEarningStatus[historyItem.EARNINGSTATUSID]}</TableCell>
                                            <TableCell>{historyItem.REVIEWRATING} Stars</TableCell>
                                            <TableCell>{historyItem.REVIEWCOMMENTS}</TableCell>
                                            <TableCell><Button disabled={historyItem.EARNINGSTATUSID !== 1} onClick={() => { setSelectedHistoryItem(historyItem); handleImages(historyItem); }}>Update</Button></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box>
                            <Modal
                                open={isUpdateOpen}
                                onClose={() => setIsUpdateOpen(false)}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={style}>
                                    <Box display='flex' justifyContent='center' margin='2rem'>
                                        <Typography variant='h5'>Update History Review</Typography>
                                    </Box>
                                    <Box sx={displayedText}>
                                        <Typography variant='body1'>Created By:</Typography>
                                        <Typography variant='h5'>{selectedHistoryItem.CREATEDBY}</Typography>
                                    </Box>
                                    <Box sx={displayedText}>
                                        <Typography variant='body1'>Updated By</Typography>
                                        <Typography variant='h5'>{selectedHistoryItem.UPDATEDBY}</Typography>
                                    </Box>
                                    <Box mb={2}>
                                        <Typography variant='body1' mb={1}>Do you want to complete the inventory count for us?</Typography>
                                        <RadioGroup
                                            value={estimateOnShelf}
                                            onChange={event => setEstimateOnShelf(Number(event.target.value))}
                                        >
                                            <FormControlLabel value={1} control={<Radio />} label="Yes" />
                                            <FormControlLabel value={0} control={<Radio />} label="No" />
                                        </RadioGroup>
                                    </Box>
                                    <Box mb={2}>
                                        <Typography variant='body1' mb={1}>Count on shelf</Typography>
                                        <TextField
                                            label="Count on shelf"
                                            variant="filled"
                                            sx={inputField}
                                            value={conutOnShelf}
                                            onChange={(e) => setCountOnShelf(Number(e.target.value))}
                                            type="number"
                                            inputProps={{
                                                min: 0,
                                                step: 1
                                            }}
                                        />
                                    </Box>
                                    <Box mb={2}>
                                        <Typography variant="h6">Upload Images</Typography>
                                        <input
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                            id="image-upload"
                                            type="file"
                                            multiple
                                            onChange={handleImageChange}
                                        />
                                        <label htmlFor="image-upload">
                                            <Button variant="contained" component="span" fullWidth>Upload Images</Button>
                                        </label>
                                    </Box>
                                    {selectedImages.length > 0 && (
                                        <Box maxWidth="md" mb={2}>
                                            <Grid container spacing={2}>
                                                {selectedImages.map((image, index) => (
                                                    <Grid item key={index} xs={12} sm={6} md={4}>
                                                        <Card>
                                                            <CardMedia
                                                                component="img"
                                                                alt={`Uploaded Image ${index + 1}`}
                                                                height="200"
                                                                image={image}
                                                            />
                                                        </Card>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Box>
                                    )}
                                    <Box display='flex' justifyContent='flex-end'>
                                        <Button variant="contained" onClick={handleUpdateReviewSubmit} sx={cancelButtonStyle}>Submit</Button>
                                        <Button variant="outlined" onClick={handleUpdateReviewClose} sx={cancelButtonStyle}>Cancel</Button>
                                    </Box>
                                </Box>
                            </Modal>
                        </Box>
                        <Box sx={buttonContainerStyle}>
                            <Button variant="contained" onClick={handleButtonSubmit}>OK</Button>
                        </Box>
                    </FormControl>
                </Box>
            </Modal>

        </>
    );
}


export default TaskHistoryModal;
