import { Box, Grid } from "@mui/material";
import { PortalCardInfo } from "../../components/portalCard/portalCard.interface";
import SiteHeader from "../../components/siteHeader/siteHeader";
import PortalCard from "../../components/portalCard/portalCard";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';

function ICOwnerPortal() {
    const portalCards: PortalCardInfo[] = [
        { id: 1, displayInfo: 'Manage Products', navigateTo: 'product', displaySvg: EmailOutlinedIcon },
        // { id: 2, displayInfo: 'Review Transaction', navigateTo: 'review-transaction', displaySvg: EventNoteOutlinedIcon },
        // { id: 3, displayInfo: 'Manage Shipping Projects', navigateTo: 'project', displaySvg: EventNoteOutlinedIcon },
        { id: 4, displayInfo: 'Manage Store Projects', navigateTo: 'project', displaySvg: EventNoteOutlinedIcon }
    ];

    return (
        <>
            <SiteHeader pageTitle='IC OWNER(SUPPLIER) PORTAL' />
            <Box margin='2rem' marginTop='7rem'>
                <Grid container spacing={2}>
                    {portalCards.map(portalCard => (
                        <Grid item xs={12} sm={6} md={6} key={portalCard.id}>
                            <PortalCard portalCard={portalCard} />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </>
    );
}

export default ICOwnerPortal;