import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { baseUrl } from '../../utils/url-config';
import { axiosInstance } from '../../utils/axios-instance';

const initialState = {
    farmingLabor: [],
    loading: false,
    error: null
};

export const fetchFarmingLabor = createAsyncThunk<any, string, { rejectValue: Error }>(
    'store/fetchFarmingLabor/get',
    async (farmingLaborStatus, thunkAPI) => {
        try {
            const response = await axiosInstance.get<any>(`${baseUrl}/job-projects/available/${farmingLaborStatus}`);
            return response.data;
        } catch (error: unknown) {
            return thunkAPI.rejectWithValue(error as Error);
        }
    }
);

const farmingLaborSlice = createSlice({
    name: 'farmingLabor',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchFarmingLabor.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchFarmingLabor.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.farmingLabor = action.payload;
            })
            .addCase(fetchFarmingLabor.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload;
            })
    },
});


export default farmingLaborSlice.reducer;
