import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const myProductSlice = createSlice({
    name: 'myProduct',
    initialState: { selectedProduct: {} },
    reducers: {
        setSelectedProduct(state, action: PayloadAction<any>) {
            state.selectedProduct = action.payload;
        },
        clearSelectedProduct(state) {
            state.selectedProduct = {};
        }
    }
});

export const { setSelectedProduct, clearSelectedProduct } = myProductSlice.actions;

export default myProductSlice.reducer;
