import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { baseUrl } from '../../utils/url-config';
import { getUserInfo } from "../../utils/authentication";
import { axiosInstance } from '../../utils/axios-instance';
import { StoreICProjectTransactionWorkflow } from '../agentprojecttransactionsworkflow';


export interface FieldAgentICProjectTransaction {
    ID: number;
    FIELDAGENTICPROJECTID: number;
    PICTURESPATH: string;
    ESTIMATEDCOUNTONSHELF: number;
    EARNINGVALUE: number;
    EARNINGVALUECURRENCY: string;
    EARNINGSTTATUSID: number;
    REVIEWCOMMENTS: string;
    REVIEWRATING: number;
    CREATEDBY: string;
    UPDATEDBY: string;
    CREATEDAT: Date;
    UPDATEDAT: Date;
}

export interface FieldAgentICProjectTransactionState {
    allTransactionsByAgentProject: FieldAgentICProjectTransaction[];
    currentTranactionByAgentProject: any;
    loading: boolean;
    error: string | null;
}

const initialState: FieldAgentICProjectTransactionState = {
    allTransactionsByAgentProject: [],
    currentTranactionByAgentProject: {},
    loading: false,
    error: null
};

/**
 * Expected payload to create a new transaction in FIELDAGENTICPROJECTTRANSACTIONS table
 */
interface updateFieldAgentICProjectTransactionPayload {
    userId: number;
    FIELDAGENTICPROJECTID: number;
    PICTURESPATH: string;
    ESTIMATEDCOUNTONSHELF: number;
    EARNINGVALUE: number;
    EARNINGCURRENCY: string;
}

/**
 * Return all the transactions for the current agent's IC Project by the agent's IC Project ID
 */
export const fetchAllTransactionsByAgentICProject = createAsyncThunk<FieldAgentICProjectTransaction[], number, { rejectValue: Error }>(
    'store/fetchAllTransactionsByAgentICProject/get',
    async (agentProjectId, thunkAPI) => {
        try {
            const response = await axiosInstance.get<FieldAgentICProjectTransaction[]>(`${baseUrl}/field-agent-ic-project-transactions/agentProjectId/${agentProjectId}`);
            return response.data;
        } catch (error: unknown) {
            return thunkAPI.rejectWithValue(error as Error);
        }
    }
);

/**
 * Find the transaction by its ID
 */
export const fetchTransactionById = createAsyncThunk<FieldAgentICProjectTransaction, void, { rejectValue: Error }>(
    'store/fetchTransactionById/get',
    async (id, thunkAPI) => {
        try {
            const response = await axiosInstance.get<FieldAgentICProjectTransaction>(`${baseUrl}/field-agent-ic-project-transactions/${id}`);
            return response.data;
        } catch (error: unknown) {
            return thunkAPI.rejectWithValue(error as Error);
        }
    }
);

/**
 * Create a new transaction in FIELDAGENTICPROJECTTRANSACTIONS table
 */
export const createFieldAgentICProjectTransactionInfo = createAsyncThunk<FieldAgentICProjectTransaction, updateFieldAgentICProjectTransactionPayload, { rejectValue: Error }>(
    'store/createFieldAgentICProjectTransactionInfo/post',
    async (payload, thunkAPI) => {
        try {
            const response = await axiosInstance.post<FieldAgentICProjectTransaction>(`${baseUrl}/field-agent-ic-project-transactions/create/user/${payload.userId}`, payload);
            return response.data;
        } catch (error: unknown) {
            return thunkAPI.rejectWithValue(error as Error);
        }
    }
);

export const updateFieldAgentICProjectTransactionInfo = createAsyncThunk<FieldAgentICProjectTransaction, any, { rejectValue: Error }>(
    'store/updateFieldAgentICProjectTransactionInfo/put',
    async (payload, thunkAPI) => {
        try {
            const response = await axiosInstance.put<any>(`${baseUrl}/field-agent-ic-project-transactions/agent-update/${payload.FIELDAGENTPROJECTTRANSACTIONID}`, payload);
            return response.data;
        } catch (error: unknown) {
            return thunkAPI.rejectWithValue(error as Error);
        }
    }
);

export const updateIcOwnerICProjectTransactionInfo = createAsyncThunk<FieldAgentICProjectTransaction, any, { rejectValue: Error }>(
    'store/updateIcOwnerICProjectTransactionInfo/put',
    async (payload, thunkAPI) => {
        try {
            const response = await axiosInstance.put<any>(`${baseUrl}/field-agent-ic-project-transactions/reviewer-update/${payload.FIELDAGENTPROJECTTRANSACTIONID}`, payload);
            return response.data;
        } catch (error: unknown) {
            return thunkAPI.rejectWithValue(error as Error);
        }
    }
);

/**
 * create a new record in the STOREPROJECTTRANSACTIONWORKFLOW table.
 */
export const insertWorkFlowForTransaction = createAsyncThunk<StoreICProjectTransactionWorkflow, any, { rejectValue: Error }>(
    'store/insertWorkFlowForTransaction/post',
    async (payload, thunkAPI) => {
        try {
            const userId = getUserInfo().ID;
            console.log("calling insertWorkFlowForTransaction to insert a record by: " + userId);
            const response = await axiosInstance.post<any>(`${baseUrl}/store-ic-project-transaction-workflow/create/user/${userId}`, payload);
            return response.data;
        } catch (error: unknown) {
            return thunkAPI.rejectWithValue(error as Error);
        }
    }
);



const agentICProjectTransactionsSlice = createSlice({
    name: 'agenticprojecttransactions',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllTransactionsByAgentICProject.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchAllTransactionsByAgentICProject.fulfilled, (state, action: PayloadAction<FieldAgentICProjectTransaction[]>) => {
                state.loading = false;
                state.allTransactionsByAgentProject = action.payload;
            })
            .addCase(fetchAllTransactionsByAgentICProject.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload;
            }).addCase(fetchTransactionById.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchTransactionById.fulfilled, (state, action: PayloadAction<FieldAgentICProjectTransaction>) => {
                state.loading = false;
                state.currentTranactionByAgentProject = action.payload;
            })
            .addCase(fetchTransactionById.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default agentICProjectTransactionsSlice.reducer;
