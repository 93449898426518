import { useEffect, useState } from "react";
import { Box, Button, TextField, useMediaQuery } from "@mui/material";
import SiteHeader from "../../../components/siteHeader/siteHeader";
import ProductFormModal from "../../../components/productFormModal/productFormModal";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store";
import { Product, fetchAllProducts } from "../../../redux/products";
import { selectAllProducts } from "../../../selectors/products";
import ICOwnerProductCardList from "./icOwnerProductCardList";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../../utils/authentication";
import UpdateProductModal from "./updateProductModal";
import { toLocalTime } from "../../../utils/to-local-time";

function ICOwnerProduct() {
    const [openModal, setOpenModal] = useState(false);
    const [openProductUpdateModal, setOpenProductUpdateModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [allProductsWithImages, setAllProductsWithImages] = useState<any[]>([]);
    const addProductHandler = () => {
        setOpenModal(true);
    }
    const dispatch = useDispatch<AppDispatch>();
    const allProducts: Product[] = useSelector(selectAllProducts);
    const isMobile = useMediaQuery('(max-width: 600px)');

    useEffect(() => {
        dispatch(fetchAllProducts());
    }, [dispatch]);

    console.log(allProducts);
    useEffect(() => {
        const allProductsWithImages = allProducts.map(async product => {
            const productImagePaths = JSON.parse(product.IMAGEPATHS);
            const urls: string[] = [];
            if (productImagePaths.length) {
                await Promise.all(
                    productImagePaths.map(async (productImagePath: string | undefined) => {
                        const imageRef = ref(storage, productImagePath);
                        await getDownloadURL(imageRef).then((url) => {
                            urls.push(url);
                        });
                    })
                );
            }
            return { ...product, imageUrls: urls };
        });

        Promise.all(allProductsWithImages).then(projects => {
            setAllProductsWithImages(projects);
        });

    }, [allProducts]);

    const rows = allProductsWithImages.filter((product) => {
        return product?.NAME?.toLowerCase().includes(searchTerm) ||
            product?.DESCRIPTION?.toLowerCase().includes(searchTerm) ||
            product?.BRAND?.toLowerCase().includes(searchTerm);
    }).map((product) => ({
        id: product.ID,
        name: product.NAME,
        description: product.DESCRIPTION,
        brand: product.BRAND,
        wholesalePrice: product.WHOLESALEPRICE,
        img: product.imageUrls.length ? product.imageUrls[0] : 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
        subCategory: product.SUBCATEGORY,
        sku: product.SKU,
        createdBy: product.CREATEDBY,
        createdAt: toLocalTime(product.createdAt),
        wholeSelePriceCurrency: product.WHOLESALEPRICECURRENCY,
        unit: product.UNIT,
        size: product.SIZE,
        productUrls: product.imageUrls,
        originalImgUrls: product.IMAGEPATHS
    }));

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value.toLowerCase());
    };


    return (
        <>
            <SiteHeader pageTitle='IC OWNER(SUPPLIER) PORTAL' />
            <Box display='flex' justifyContent='center' marginTop={isMobile ? '20%' : '15%'}>
                <TextField label="Search Product"
                    variant="standard"
                    onChange={handleSearchChange} />
            </Box>
            <Box display='flex' justifyContent='center' marginTop='5%'>
                <Button variant="contained" onClick={addProductHandler}>Add Product</Button>
            </Box>
            <Box marginTop='5%' display='flex' justifyContent='center'>
                <ICOwnerProductCardList rows={rows} setIsProductUpdateModalOpen={setOpenProductUpdateModal} />
            </Box>
            <Box>
                <ProductFormModal isModalOpen={openModal} setIsModalOpen={setOpenModal} />
            </Box>
            <Box>
                <UpdateProductModal isModalOpen={openProductUpdateModal} setIsModalOpen={setOpenProductUpdateModal} />
            </Box>
        </>
    )
}


export default ICOwnerProduct;
