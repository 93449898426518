import { configureStore } from '@reduxjs/toolkit';
import myTaskSlice from './myTaskSlice';
import usersSlice from '../redux/users';
import storesSlice from '../redux/stores';
import productsSlice from '../redux/products';
import icProjectsSlice from '../redux/projects';
import agenticProjectsSlice from '../redux/agentprojects';
import agentICProjectTransactionsSlice from '../redux/agentprojecttransactions';
import agentICProjectTransactionWorkflowSlice from '../redux/agentprojecttransactionsworkflow';
import myProductSlice from './myProductSlice';
import myProjectSlice from './myProjectSlice';
import farmingLaborSlice from '../redux/farmingLabor';


//This store is NOT the Store domain model our application uses, but the Redux store
const store = configureStore({
    reducer: {
        myProject: myProjectSlice,
        myProduct: myProductSlice,
        myTask: myTaskSlice,
        users: usersSlice,
        stores: storesSlice,
        products: productsSlice,
        icprojects: icProjectsSlice,
        agenticprojects: agenticProjectsSlice,
        agenticprojecttransactions: agentICProjectTransactionsSlice,
        agenticprojecttransactionsworkflow: agentICProjectTransactionWorkflowSlice,
        farmingLabor: farmingLaborSlice
    }
});

export type AppDispatch = typeof store.dispatch;

export default store;

