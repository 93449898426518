import styled from "@emotion/styled";
import { Box, IconButton, Menu, MenuItem, Typography, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from "react";

interface SiteHeaderProps {
    pageTitle: string;
}

const StyledLink = styled(Link)`
  text-decoration: none;
  color: white;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: #ffc107;
  }
`;

const HeaderContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem;
  background-color: #2a3a8e;
  color: white;
  border-bottom: 4px solid #ffc107;
  position: fixed;
  top: 0;
  z-index: 1000;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;

  .my-openic-logo {
    font-size: 2.5rem;
    font-weight: bold;
    margin-right: 0.5rem;
  }

  h6 {
    margin: 0;
    color: #ffc107;
  }
`;

const HeaderLinks = styled(Box)`
  display: flex;
  align-items: center;

  .header-link {
    margin-right: 1.5rem;
    font-weight: bold;
  }

  .logout-button {
    cursor: pointer;
    font-weight: bold;
  }
`;

const InitialPageSiteHeader: React.FC<SiteHeaderProps> = ({ pageTitle }) => {
    const isMobile = useMediaQuery('(max-width: 600px)'); // Define your mobile breakpoint

    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget as any);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <HeaderContainer>
            <LogoWrapper>
                <StyledLink className="my-openic-logo" to='/home'>myOpenIC</StyledLink>
                {!isMobile && <Typography variant="h6">{pageTitle}</Typography>}
            </LogoWrapper>
            {!isMobile ? (
                <HeaderLinks>
                    <StyledLink className="header-link" to='/initial'>Initial Page</StyledLink>
                    <StyledLink className="header-link" to='/why-us'>Why myOpenIC</StyledLink>
                    <StyledLink className="header-link" to='/about-us'>About US</StyledLink>
                    <StyledLink className="header-link" to='/login'>Sign In</StyledLink>
                    <StyledLink className="header-link" to='/sign-up'>Sign Up</StyledLink>
                </HeaderLinks>
            ) : (
                <>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        aria-controls="header-menu"
                        aria-haspopup="true"
                        onClick={handleMenuClick}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        id="header-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        <MenuItem component={Link} to="/initial" onClick={handleMenuClose}>
                            Initial Page
                        </MenuItem>
                        <MenuItem component={Link} to="/why-us" onClick={handleMenuClose}>
                            Why myOpenIC
                        </MenuItem>
                        <MenuItem component={Link} to="/about-us" onClick={handleMenuClose}>
                            About US
                        </MenuItem>
                        <MenuItem component={Link} to="/login" onClick={handleMenuClose}>
                            Sign In
                        </MenuItem>
                        <MenuItem component={Link} to="/sign-up" onClick={handleMenuClose}>
                            Sign Up
                        </MenuItem>
                    </Menu>
                </>
            )}
        </HeaderContainer>
    );
}

export default InitialPageSiteHeader;