import { useState } from 'react';
import ProjectModal from '../projectModal/projectModal';
import ProjectTableCardList from './projectTableCardList';


function ProjectDataTable({ rows }: any) {
    const [openModal, setOpenModal] = useState(false);
    const [currentRow, setCurrentRow] = useState({});

    return (
        <div style={{ height: 400, width: '50%' }}>
            <ProjectModal isModalOpen={openModal} setIsModalOpen={setOpenModal} rowData={currentRow} />
            <ProjectTableCardList rows={rows} setCurrentRow={setCurrentRow} setOpenModal={setOpenModal} />
        </div>
    );
}

export default ProjectDataTable;
