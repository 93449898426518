import React from 'react';
import { Box, Typography, Divider } from '@mui/material';
import SiteHeader from '../../components/siteHeader/siteHeader';

const AboutUs = () => {
    return (
        <Box>
            <SiteHeader pageTitle='About Us' />
            <Box
                sx={{
                    backgroundColor: '#f5f5f5',
                    padding: '24px',
                    borderRadius: '8px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    color: '#333',
                    marginTop: '5rem',
                }}
            >
                <Typography variant="h3" sx={{ color: '#333', fontWeight: 'bold', marginBottom: '24px', textAlign: 'center' }}>
                    About Us
                </Typography>
                <Divider variant="middle" />
                <Box mt={6}>
                    <Typography variant="body1" sx={{ color: '#666', marginBottom: '24px' }}>
                        <strong>Do you need to find an independent agent to help you sometimes?</strong>
                        <strong> Do you need to check or manage your inventory in stores?</strong>
                        <strong> Do you need someone to take pictures of your inventory and property?</strong>
                        <strong> Find your agent and inventory planner from anywhere, on myOpenIC!</strong>
                    </Typography>
                    <Typography variant="body1" sx={{ color: '#666', marginBottom: '24px' }}>
                        myOpenIC was born from a simple yet powerful question:
                        how can we check inventory in places <strong>faster, easier, and more cost-effective</strong> for businesses of all sizes?
                    </Typography>
                    <Typography variant="body1" sx={{ color: '#666', marginBottom: '24px' }}>
                        We started with the supermarket industry, where manual inventory checks can be a major drain on time and resources.
                        Our open platform <strong>connects suppliers and field agents seamlessly,</strong> automating tasks and providing real-time data insights.
                        <strong>But myOpenIC is not just for supermarkets!</strong> Our platform is <strong>flexible and adaptable, </strong>
                        designed to tackle any inventory control challenge, regardless of industry.
                    </Typography>
                    <Typography variant="body1" sx={{ color: '#666', marginBottom: '24px' }}>
                        <strong>Here's what makes us different:</strong>
                    </Typography>
                    <Typography variant="body1" sx={{ color: '#666', marginBottom: '24px' }}>
                        <ul>
                            <li><strong>Open and Customizable: </strong>We don't believe in one-size-fits-all solutions.
                                Our platform is built to be customized to your specific needs,
                                integrating seamlessly with your existing systems and workflows.</li>
                            <li><strong>Cutting-Edge Technology: </strong>We leverage the latest advancements in data analytics and automation to provide you with powerful tools that optimize stock management, improve collaboration, and streamline operations.</li>
                            <li><strong>Passionate Team: </strong>We're a team of experts who are passionate about inventory management and committed to helping businesses succeed. We're constantly innovating and evolving to stay ahead of the curve. myOpenIC can be used even out of the box.  Imagining that you need someone to check your family members’ house condition in Hawaii when you are in NewYork, you can simply create a project and find an agent to help you!  Even though we are in the OpenAI era, we still have needs that must be accomplished by humans.</li>
                        </ul>
                    </Typography>
                    <Typography variant="body1" sx={{ color: '#666', marginBottom: '24px' }}>
                        myOpenIC can be used even out of the box.
                        Imagining that you need someone to check your family members’ house condition in Hawaii when you are in NewYork,
                        you can simply create a project and find an agent to help you!
                        Even though we are in the OpenAI era, we still have needs that must be accomplished by humans.
                    </Typography>
                    <Typography variant="body1" sx={{ color: '#666', marginBottom: '24px' }}>
                        <strong>Our Mission:</strong>
                    </Typography>
                    <Typography variant="body1" sx={{ color: '#666', marginBottom: '24px' }}>
                        At myOpenIC, we are on a mission to <strong>transform inventory management</strong> for the better.
                        We believe that by providing businesses with open, accessible, and intelligent tools,
                        we can <strong>drive efficiency, reduce costs, and unlock new opportunities for growth.</strong>
                    </Typography>
                    <Typography variant="body1" sx={{ color: '#666', marginBottom: '24px' }}>
                        We strive to create a seamless experience for supermarkets and suppliers, enabling them to efficiently manage
                        inventory, improve stock accuracy, and foster stronger relationships.
                    </Typography>
                    <Typography variant="body1" sx={{ color: '#666', marginBottom: '24px' }}>
                        <strong>Join us on the journey! </strong>
                        Contact us <a href="mailto:sales@myopenic.com">sales@myopenic.com</a> today to learn more about how myOpenIC can help you take control of your inventory and unlock your business potential.
                    </Typography>
                    <Divider variant="middle" />

                </Box>
            </Box>
        </Box>
    );
};

export default AboutUs;
