import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { baseUrl } from '../../utils/url-config';

export interface Store {
    ID: number;
    STORENUMBER: string;
    NAME: string;
    CHAINNAME: string;
    STARTDATE: string;
    ADDRESS1: string;
    ADDRESS2: string;
    CITY: string;
    STATE: string;
    ZIPCODE: string;
    TELEPHONE: string;
    CREATEDBY: string;
    UPDATEDBY: string;
}

export interface StoreState {
    allStores: Store[];
    currentStore: any;
    loading: boolean;
    error: string | null;
}

const initialState: StoreState = {
    allStores: [],
    currentStore: {},
    loading: false,
    error: null
};

//Return all the stores
export const fetchAllStores = createAsyncThunk<Store[], void, { rejectValue: Error }>(
    'store/fetchAllStores/get',
    async (_, thunkAPI) => {
        try {
            const response = await axios.get<Store[]>(`${baseUrl}/stores`);
            return response.data;
        } catch (error: unknown) {
            return thunkAPI.rejectWithValue(error as Error);
        }
    }
);

//Return the store by store id
export const fetchStoreInfo = createAsyncThunk<Store, string, { rejectValue: Error }>(
    'store/fetchStoreInfo/get',
    async (id, thunkAPI) => {
        try {
            const response = await axios.get<Store>(`${baseUrl}/stores/${id}`);
            return response.data;
        } catch (error: unknown) {
            return thunkAPI.rejectWithValue(error as Error);
        }
    }
);

const storesSlice = createSlice({
    name: 'stores',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllStores.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchAllStores.fulfilled, (state, action: PayloadAction<Store[]>) => {
                state.loading = false;
                state.allStores = action.payload;
            })
            .addCase(fetchAllStores.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload;
            }).addCase(fetchStoreInfo.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchStoreInfo.fulfilled, (state, action: PayloadAction<Store>) => {
                state.loading = false;
                state.currentStore = action.payload;
            })
            .addCase(fetchStoreInfo.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default storesSlice.reducer;
