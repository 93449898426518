import { Box, Modal, Typography, Button, FormControl, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CardMedia, Card, Grid, TextField, Zoom, styled } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { FieldAgentEarningStatus } from '../../../utils/constants';
import React, { useEffect, useMemo, useState } from 'react';
import { getDownloadURL, ref } from 'firebase/storage';
import { getUserInfo, storage } from '../../../utils/authentication';
import { AppDispatch } from '../../../store';
import { fetchAllICProjects } from '../../../redux/projects';
import { insertWorkFlowForTransaction, updateIcOwnerICProjectTransactionInfo } from '../../../redux/agentprojecttransactions';
import { toLocalTime } from '../../../utils/to-local-time';


interface TaskHistoryModalProps {
    isModalOpen: boolean;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const style: React.CSSProperties = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: '800px',
    background: 'white',
    border: '2px solid #ccc',
    borderRadius: '8px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    padding: 5,
    overflowY: 'auto',
    maxHeight: '90vh'
};

const displayedText = {
    marginTop: '1.5rem'
}

const cancelButtonStyle: React.CSSProperties = {
    marginRight: '1rem',
};

const inputField = {
    margin: '2% 0',
    width: '85%'
}

const zoomIn = {
    cursor: 'zoom-in'
}

const zoomOut = {
    cursor: 'zoom-out'
}

const tableHeadCellStyle: React.CSSProperties = {
    fontWeight: 'bold',
};

const buttonContainerStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '2rem',
};

const ZoomedImage = styled('img')({
    position: 'absolute',
    left: 0,
    top: '100vh',
    maxWidth: '80vw',
    border: '1px solid black',
    zIndex: 1000,

});

function ReviewTransactionModal({ isModalOpen, setIsModalOpen }: TaskHistoryModalProps) {
    const userInfo = getUserInfo();
    const selectedTask = useSelector((state: any) => state.myTask.selectedTask);
    const selectedHistory = useMemo(() => {
        return selectedTask.FieldAgentICProjectTransactions ? selectedTask.FieldAgentICProjectTransactions : [];
    }, [selectedTask.FieldAgentICProjectTransactions]);

    const [isUpdateOpen, setIsUpdateOpen] = useState(false);
    const [selectedHistoryItem, setSelectedHistoryItem] = useState<any>({});
    const [earningStatusId, setEarningStatusId] = useState<number>(selectedHistoryItem.EARNINGSTATUSID);
    const [finalAjustedCount, setFinalAjustedCount] = useState<number>(selectedHistoryItem.FINALADJUSTEDCOUNT);
    const [reviewComments, setReviewComments] = useState<string>(selectedHistoryItem.REVIEWCOMMENTS);
    const [reviewRating, setReviewRating] = useState<number>(selectedHistoryItem.REVIEWRATING);
    const [selectedImages, setSelectedImages] = useState<string[]>([]);
    const [zoomImage, setZoomImage] = useState<boolean | undefined>(false);
    const dispatch = useDispatch<AppDispatch>();

    const handleClose = (event: React.MouseEvent<HTMLElement>, reason: string) => {
        event.preventDefault();
        if (reason === 'backdropClick') {
            return;
        }
        setIsModalOpen(false);
    };

    const handleButtonSubmit = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        setEarningStatusId(selectedHistoryItem.EARNINGSTATUSID);
        setFinalAjustedCount(selectedHistoryItem.FINALADJUSTEDCOUNT);
        setReviewComments(selectedHistoryItem.REVIEWCOMMENTS);
        setReviewRating(selectedHistoryItem.REVIEWRATING);
    }, [selectedHistory, selectedHistory.EARNINGSTATUSID, selectedHistory.FINALADJUSTEDCOUNT, selectedHistory.REVIEWCOMMENTS, selectedHistory.REVIEWRATING, selectedHistoryItem])


    const handleImages = async (historyItem: any) => {
        if (historyItem.PICTURESPATH && historyItem.PICTURESPATH.length) {
            const urls: React.SetStateAction<string[]> = [];
            await Promise.all(
                JSON.parse(historyItem.PICTURESPATH).map(async (productImagePath: string | undefined) => {
                    const imageRef = ref(storage, productImagePath);
                    const url = await getDownloadURL(imageRef);
                    urls.push(url);
                })
            );
            setSelectedImages(urls);
        } else {
            setSelectedImages([]);
        }
        setIsUpdateOpen(true);
    }

    const handleUpdateReviewApproveSubmit = async () => {
        const payloadForUpdateTransaction = {
            EARNINGSTATUSID: 2, // Review status id is always 2
            FINALADJUSTEDCOUNT: finalAjustedCount,
            REVIEWERID: userInfo.ID,
            REVIEWCOMMENTS: reviewComments,
            REVIEWRATING: reviewRating,
            UPDATEDBY: userInfo.NAME ? userInfo.NAME : userInfo.EMAIL,
            FIELDAGENTPROJECTTRANSACTIONID: selectedHistoryItem.ID
        }

        const payLoadForWorkFlow = {
            FIELDAGENTSTOREICPROJECTTRANSACTIONID: selectedHistoryItem.ID,
            EARNINGSTATUSID: 2
        }

        await dispatch(updateIcOwnerICProjectTransactionInfo(payloadForUpdateTransaction));
        //insert new record for workflow
        await dispatch(insertWorkFlowForTransaction(payLoadForWorkFlow));
        setIsUpdateOpen(false);
        setIsModalOpen(false);
        dispatch(fetchAllICProjects());
    }

    /**
     * This function is used to reject the review
     * Dacheng, please see how to consolidate the approve and reject functions
     */
    const handleUpdateReviewRejectSubmit = async () => {
        const payloadForUpdateTransaction = {
            EARNINGSTATUSID: 3, // Review status id is always 3
            FINALADJUSTEDCOUNT: finalAjustedCount,
            REVIEWERID: userInfo.ID,
            REVIEWCOMMENTS: reviewComments,
            REVIEWRATING: reviewRating,
            UPDATEDBY: userInfo.NAME ? userInfo.NAME : userInfo.EMAIL,
            FIELDAGENTPROJECTTRANSACTIONID: selectedHistoryItem.ID
        }

        const payLoadForWorkFlow = {
            FIELDAGENTSTOREICPROJECTTRANSACTIONID: selectedHistoryItem.ID,
            EARNINGSTATUSID: 3
        }

        await dispatch(updateIcOwnerICProjectTransactionInfo(payloadForUpdateTransaction));
        //insert new record for workflow
        await dispatch(insertWorkFlowForTransaction(payLoadForWorkFlow));


        setIsUpdateOpen(false);
        setIsModalOpen(false);
        dispatch(fetchAllICProjects());
    }


    const handleUpdateReviewClose = () => {
        setIsUpdateOpen(false);
    }

    return (
        <>
            <Modal
                open={isModalOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <FormControl variant="standard">
                        <Box display='flex' justifyContent='center' margin='2rem'>
                            <Typography variant='h5'>Review Transaction</Typography>
                        </Box>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={tableHeadCellStyle}>Store Visit Date/TIME</TableCell>
                                        <TableCell style={tableHeadCellStyle}>Earning</TableCell>
                                        <TableCell style={tableHeadCellStyle}>Payment Status</TableCell>
                                        <TableCell style={tableHeadCellStyle}>Supplier Rating</TableCell>
                                        <TableCell style={tableHeadCellStyle}>Supplier Comment</TableCell>
                                        <TableCell style={tableHeadCellStyle}>Review Transaction</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {selectedHistory.filter((historyItem: { EARNINGSTATUSID: number; }) => historyItem.EARNINGSTATUSID === 1).map((historyItem: any, index: number) => (
                                        <TableRow key={index}>
                                            <TableCell>{toLocalTime(historyItem.createdAt)}</TableCell>
                                            <TableCell>{historyItem.EARNINGVALUE} {historyItem.EARNINGCURRENCY}</TableCell>
                                            <TableCell>{FieldAgentEarningStatus[historyItem.EARNINGSTATUSID]}</TableCell>
                                            <TableCell>{historyItem.REVIEWRATING} Stars</TableCell>
                                            <TableCell>{historyItem.REVIEWCOMMENTS}</TableCell>
                                            <TableCell><Button onClick={() => { setSelectedHistoryItem(historyItem); handleImages(historyItem); }}>Review</Button></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box>
                            <Modal
                                open={isUpdateOpen}
                                onClose={() => setIsUpdateOpen(false)}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={style}>
                                    <Box display='flex' justifyContent='center' margin='2rem'>
                                        <Typography variant='h5'>Review this transaction</Typography>
                                    </Box>
                                    <Box sx={displayedText}>
                                        <Typography variant='body1'>Created By:</Typography>
                                        <Typography variant='h5'>{selectedHistoryItem.CREATEDBY}</Typography>
                                    </Box>
                                    <Box sx={displayedText}>
                                        <Typography variant='body1'>Updated By</Typography>
                                        <Typography variant='h5'>{selectedHistoryItem.UPDATEDBY}</Typography>
                                    </Box>
                                    <Box mb={2}>
                                        <Typography variant='body1' mb={1}>Earning Value</Typography>
                                        <Typography variant='h5'>{selectedHistoryItem.EARNINGVALUE} {selectedHistoryItem.EARNINGCURRENCY}</Typography>
                                    </Box>
                                    <Box mb={2}>
                                        <Typography variant='body1' mb={1}>Do you want to complete the inventory count for us?</Typography>
                                        <Typography variant='h5'>{selectedHistoryItem.ESTIMATECOUNTONSHELF ? 'Yes' : 'No'}</Typography>
                                    </Box>
                                    <Box mb={2}>
                                        <Typography variant='body1' mb={1}>Count on shelf</Typography>
                                        <Typography variant='h5'>{selectedHistoryItem.ESTIMATEDCOUNTONSHELF}</Typography>
                                    </Box>

                                    <TextField label="Final Adjusted Count" value={finalAjustedCount} variant="filled" type="number" sx={inputField} onChange={(e) => { setFinalAjustedCount(Number(e.target.value)); }} />
                                    <TextField label="Review Comments" value={reviewComments} variant="filled" sx={inputField} onChange={(e) => { setReviewComments(e.target.value); }} />
                                    <TextField
                                        label="Review Rating (0-5)"
                                        type="number"
                                        variant="filled"
                                        value={reviewRating}
                                        sx={inputField}
                                        onChange={(e) => { setReviewRating(Number(e.target.value)); }}
                                        inputProps={{
                                            min: 0,
                                            max: 5
                                        }}
                                    />

                                    {selectedImages.length > 0 && (
                                        <Box maxWidth="md" mb={2}>
                                            <Grid container spacing={2}>
                                                {selectedImages.map((image, index) => (
                                                    <Grid item key={index} xs={12} sm={6} md={4}>
                                                        <Card>
                                                            <CardMedia
                                                                component="img"
                                                                alt={`Uploaded Image ${index + 1}`}
                                                                height="200"
                                                                image={image}
                                                                style={zoomIn}
                                                                onClick={() => setZoomImage(true)}
                                                            />
                                                            <Zoom in={zoomImage}
                                                                style={{
                                                                    transitionDelay: zoomImage ?
                                                                        '200ms' : '0ms'
                                                                }}>
                                                                <ZoomedImage style={zoomOut} src={image} onClick={() => setZoomImage(false)} />
                                                            </Zoom>
                                                        </Card>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Box>
                                    )}
                                    <Box display='flex' justifyContent='flex-end'>
                                        <Button variant="contained" onClick={handleUpdateReviewApproveSubmit} sx={cancelButtonStyle}>Approve</Button>
                                        <Button variant="contained" onClick={handleUpdateReviewRejectSubmit} sx={cancelButtonStyle}>Reject</Button>
                                        <Button variant="outlined" onClick={handleUpdateReviewClose} sx={cancelButtonStyle}>Cancel</Button>
                                    </Box>
                                </Box>
                            </Modal>
                        </Box>
                        <Box sx={buttonContainerStyle}>
                            <Button variant="contained" onClick={handleButtonSubmit}>OK</Button>
                        </Box>
                    </FormControl>
                </Box>
            </Modal>

        </>
    );
}


export default ReviewTransactionModal;
