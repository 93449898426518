import { Box, Grid } from "@mui/material";
import { PortalCardInfo } from "../../components/portalCard/portalCard.interface";
import SiteHeader from "../../components/siteHeader/siteHeader";
import PortalCard from "../../components/portalCard/portalCard";
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';
import DoorbellOutlinedIcon from '@mui/icons-material/DoorbellOutlined';
import EditCalendarOutlinedIcon from '@mui/icons-material/EditCalendarOutlined';

function ICFieldAgentPortal() {
    const portalCards: PortalCardInfo[] = [
        { id: 1, displayInfo: 'How to become a good field agent', navigateTo: 'how-to-become-ic-agent', displaySvg: Diversity3OutlinedIcon },
        { id: 2, displayInfo: 'Apply New', navigateTo: 'apply-new', displaySvg: DoorbellOutlinedIcon },
        { id: 3, displayInfo: 'My Tasks', navigateTo: 'my-tasks', displaySvg: EditCalendarOutlinedIcon }
    ];

    return (
        <>
            <SiteHeader pageTitle='FIELD AGENT PORTAL' />
            <Box margin='2rem' marginTop='7rem'>
                <Grid container spacing={2}>
                    {portalCards.map(portalCard => (
                        <Grid item xs={12} sm={6} md={4} key={portalCard.id}>
                            <PortalCard portalCard={portalCard} />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </>
    );
}

export default ICFieldAgentPortal;