import { CSSProperties, useState, useEffect } from "react";
import { Box, Button, TextField, Typography, Modal, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { AppDispatch } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { selectAllStores } from "../../selectors/stores";
import { Store, fetchAllStores } from "../../redux/stores";
import { selectAllProducts } from "../../selectors/products";
import { fetchAllProducts } from "../../redux/products";
import { getUserInfo } from "../../utils/authentication";
import { createIcProject, fetchAllICProjects } from "../../redux/projects";
import { createStoreIcProject } from "../../redux/storeicprojects";


const modalStyle: CSSProperties = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%', // Adjust width here
    maxWidth: '1000px', // Adjust maxWidth here
    backgroundColor: 'white',
    border: '2px solid grey',
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
    borderRadius: '8px',
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxHeight: '90vh',
    overflowY: 'auto'
};

const formFieldStyle: CSSProperties = {
    width: '80%',
    marginBottom: '1.5rem',
};

function ICProjectFormModal({ isModalOpen, setIsModalOpen }: any) {
    const user = getUserInfo();
    const [selectedStoreValue, setSelectedStoreValue] = useState<string>('');
    const [selectedProductValue, setSelectedProductValue] = useState<string>('');
    const [icProjectName, setIcProjectName] = useState<string>('');
    const [icProjectDescription, setIcProjectDescription] = useState<string>('');
    const [aisleLocation, setAisleLocation] = useState<string>('');
    const [reviewCycle, setReviewCycle] = useState<string>('');
    const [reviewInstruction, setReviewInstruction] = useState<string>('');
    const [startDate, setStartDate] = useState<string>();
    const [endDate, setEndDate] = useState<string>();
    const [earningType, setEarningType] = useState<string>('');
    const [earningValue, setEarningValue] = useState<string>('');
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleButtonSubmit = async () => {
        const payload = {
            ICPROJECTTYPEID: 1, // TODO: store ic project, use enum
            OWNERID: user.ID,
            NAME: icProjectName,
            DESCRIPTION: icProjectDescription,
            DETAILINSTRUCTION: reviewInstruction,
            PRODUCTID: selectedProductValue,
            STOREID: selectedStoreValue,
            REVIEWCYCLE: reviewCycle,
            STARTDATE: startDate,
            ENDDATE: endDate,
            EARNINGTYPE: earningType,
            EARNINGVALUE: earningValue,
            AVAILABLE: true,
            AISLELOCATION: aisleLocation,
            CREATEDBY: user.NAME ? user.NAME : user.EMAIL,
            UPDATEDBY: user.NAME ? user.NAME : user.EMAIL
        }

        const response = await dispatch(createIcProject(payload));

        if (response && response.payload) {
            const ICPROJECTID = (response.payload as any).ID;
            await dispatch(createStoreIcProject({ ...payload, ICPROJECTID }));
        }

        dispatch(fetchAllICProjects());
        setIsModalOpen(false);
    };





    const dispatch = useDispatch<AppDispatch>();
    const allStores: Store[] = useSelector(selectAllStores);
    const allProducts: Product[] = useSelector(selectAllProducts);

    useEffect(() => {
        dispatch(fetchAllStores());
        dispatch(fetchAllProducts());
    }, [dispatch]);

    interface Product {
        ID: number;
        NAME: string;
        DESCRIPTION: string;
        CREATEDBY: string;
        UPDATEDBY: string;
    }

    const handleStoreChange = (event: SelectChangeEvent<string>) => {
        setSelectedStoreValue(event.target.value);
    };
    const handleProductChange = (event: SelectChangeEvent<string>) => {
        setSelectedProductValue(event.target.value);
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIcProjectName(event.target.value);
    }

    const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIcProjectDescription(event.target.value);
    }

    const handleAisleLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAisleLocation(event.target.value);
    }

    const handleReviewCycleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setReviewCycle(event.target.value);
    }

    const handleReviewInstructionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setReviewInstruction(event.target.value);
    }

    return (
        <Modal
            open={isModalOpen}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div style={modalStyle}>
                <Typography variant='h5' gutterBottom>Add a New (store) IC Project</Typography>
                <FormControl style={formFieldStyle}>
                    <TextField label="Name of the IC Porject" variant="outlined" onChange={handleNameChange} />
                </FormControl>
                <FormControl style={formFieldStyle}>
                    <TextField label="Description of the IC Project" variant="outlined" onChange={handleDescriptionChange} />
                </FormControl>
                <FormControl style={{ width: '80%', marginBottom: '1rem' }}>
                    <InputLabel id="dropdown-label">Select a Store</InputLabel>
                    <Select
                        labelId="dropdown-label"
                        id="dropdown"
                        value={selectedStoreValue}
                        label="Select an Option"
                        onChange={handleStoreChange}
                    >
                        {allStores.map((item) => (
                            <MenuItem key={item.ID} value={item.ID}>
                                {item.NAME}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl style={{ width: '80%', marginBottom: '1rem' }}>
                    <InputLabel id="product-dropdown-label">Select a Product</InputLabel>
                    <Select
                        labelId="product-dropdown-label"
                        id="product-dropdown"
                        value={selectedProductValue}
                        label="Select a Product"
                        onChange={handleProductChange}
                    >
                        {allProducts.length > 0 ? allProducts.map((product) => (
                            <MenuItem key={product.ID} value={product.ID}>
                                {product.DESCRIPTION}
                            </MenuItem>
                        )) :
                            <MenuItem disabled value={0}>Please add a product first</MenuItem>}
                    </Select>
                </FormControl>
                <FormControl style={formFieldStyle}>
                    <TextField label="Specify aisle location" variant="outlined" onChange={handleAisleLocationChange} />
                </FormControl>
                <FormControl style={formFieldStyle}>
                    <TextField label="Sepcial review cycle" variant="outlined" onChange={handleReviewCycleChange} />
                </FormControl>
                <FormControl style={formFieldStyle}>
                    <TextField label="Detailed Review Instruction" variant="outlined" onChange={handleReviewInstructionChange} />
                </FormControl>
                <FormControl style={formFieldStyle}>
                    <TextField label="Earning Type" variant="outlined" onChange={(e) => { setEarningType(e.target.value) }} />
                </FormControl>
                <FormControl style={formFieldStyle}>
                    <TextField label="Earning Value" variant="outlined" onChange={(e) => { setEarningValue(e.target.value) }} />
                </FormControl>
                <FormControl style={formFieldStyle}>
                    <span>Start Date</span>
                    <TextField
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                    />
                </FormControl>
                <FormControl style={formFieldStyle}>
                    <span>End Date</span>
                    <TextField
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                    />
                </FormControl>
                <Box display='flex' justifyContent='space-between' width='80%' marginTop='1rem'>
                    <Button variant="outlined" onClick={handleCloseModal}>Cancel</Button>
                    <Button variant="contained" onClick={handleButtonSubmit}>Submit</Button>
                </Box>
            </div>
        </Modal>
    );
}

export default ICProjectFormModal;