import { useState } from "react";
import { Box, LinearProgress, Menu, MenuItem, Stack, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store";
import { useEffect } from "react";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../utils/authentication";
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, Pagination } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from "react-router-dom";
import { fetchFarmingLabor } from "../../redux/farmingLabor";
import { selectAllFarmingLabor } from "../../selectors/farmingLabor";

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const FarmingLaborCardList: React.FC<any> = ({ rows }) => {
    const [expandedId, setExpandedId] = React.useState<number | null>(null);
    const [page, setPage] = React.useState(1);
    const navigate = useNavigate();
    const itemsPerPage = 9;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleExpandClick = (id: number) => {
        setExpandedId(id === expandedId ? null : id);
    };

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };


    const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
        setPage(newPage);
    };

    const paginatedRows = rows.slice((page - 1) * itemsPerPage, page * itemsPerPage);


    return (
        <React.Fragment>
            <Grid container spacing={2}>
                {paginatedRows.map((row: any) => (
                    <Grid item key={row.id} xs={12} sm={6} md={4}>
                        <Card sx={{ marginBottom: '20px' }}>
                            <CardHeader
                                title={`${row.jobTitle} at ${row.farmName}`}
                                subheader={`Start Date ${row.startDate} End Date ${row.endDate}`}
                                action={
                                    <>
                                        <IconButton aria-label="settings" onClick={(e) => handleMenuOpen(e)}>
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            id="action-menu"
                                            anchorEl={anchorEl}
                                            open={Boolean(anchorEl)}
                                            onClose={handleMenuClose}
                                        >
                                            <MenuItem onClick={() => { navigate('/login'); handleMenuClose() }}>Apply this project</MenuItem>
                                        </Menu>
                                    </>
                                }
                            />
                            <CardMedia
                                component="img"
                                height="194"
                                image={row.img}
                                alt={row.farmDescription}
                                sx={{ objectFit: 'contain' }}
                            />
                            <CardContent>
                                <Typography variant="body2" color="text.secondary">
                                    Earning: {row.hourlyRate}, {row.wageCurrency} per hour
                                </Typography>
                            </CardContent>
                            <CardActions disableSpacing>
                                <ExpandMore
                                    onClick={() => handleExpandClick(row.id)}
                                    aria-expanded={expandedId === row.id}
                                    aria-label="show more"
                                    expand={expandedId === row.id}
                                >
                                    <ExpandMoreIcon />
                                </ExpandMore>
                            </CardActions>
                            <Collapse in={expandedId === row.id} timeout="auto">
                                <CardContent>
                                    <Typography paragraph>Earning: {row.hourlyRate}</Typography>
                                    <Typography paragraph>
                                        {row.jobDescription} is between {String(row.startDate)} and {String(row.endDate)}.
                                    </Typography>
                                    <Typography>
                                        The image shows: {row.farmName}
                                    </Typography>
                                </CardContent>
                            </Collapse>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <Pagination
                count={Math.ceil(rows.length / itemsPerPage)}
                page={page}
                onChange={handleChangePage}
                color="primary"
                style={{ marginTop: '20px', justifyContent: 'center', display: 'flex' }}
            />
        </React.Fragment>
    );
};

function FarmingLabor() {
    const [searchTerm, setSearchTerm] = useState('');

    const dispatch = useDispatch<AppDispatch>();
    const allFarmingProjects: any[] = useSelector(selectAllFarmingLabor);
    const [allAvailableFarmingLabor, setAllAvailableFarmingLabor] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        dispatch(fetchFarmingLabor('true'));
    }, [dispatch]);

    useEffect(() => {
        setIsLoading(true);
        const allActiveFarmLabor = allFarmingProjects.map(async farmingLabor => {
            const farmingLaborImagePaths = farmingLabor.FarmJobProject && farmingLabor.FarmJobProject.Farm
                ? JSON.parse(farmingLabor.FarmJobProject.Farm.IMAGEPATHS) : [];
            const urls: string[] = [];
            if (farmingLaborImagePaths.length) {
                await Promise.all(
                    farmingLaborImagePaths.map(async (farmingLaborImagePath: string | undefined) => {
                        const imageRef = ref(storage, farmingLaborImagePath);
                        await getDownloadURL(imageRef).then((url) => {
                            urls.push(url);
                        });
                    })
                );
            }
            return { ...farmingLabor, imageUrls: urls };
        });

        Promise.all(allActiveFarmLabor).then(farmLabors => {
            setAllAvailableFarmingLabor(farmLabors);
            setIsLoading(false);
        });

    }, [allFarmingProjects]);

    const rows = allAvailableFarmingLabor.filter((farmingLabor: any) => {
        return farmingLabor?.JOBDESCRIPTION?.toLowerCase().includes(searchTerm)
    }).map((farmLabor: any) => ({
        id: farmLabor.ID,
        jobDescription: farmLabor.JOBDESCRIPTION,
        jobProjectTypeId: farmLabor.JOBPROJECTTYPEID,
        jobRequirement: farmLabor.JOBREQUIREMENT,
        jobTitle: farmLabor.JOBTITLE,
        workersNeeded: farmLabor.NUMBEROFWORKERNEEDED,
        openingLeft: farmLabor.OPENINGLEFT,
        startDate: farmLabor.STARTDATE,
        endDate: farmLabor.ENDDATE,
        img: farmLabor.imageUrls.length ? farmLabor.imageUrls[0] : 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
        benefit: farmLabor.FarmJobProject?.BENEFIT,
        hourlyRate: farmLabor.FarmJobProject?.HOURLYRATE,
        wageCurrency: farmLabor.FarmJobProject?.WAGECURRENCY,
        farmDescription: farmLabor.FarmJobProject?.Farm?.DESCRIPTION,
        farmAddress: farmLabor.FarmJobProject?.Farm?.ADDRESS1,
        farmCity: farmLabor.FarmJobProject?.Farm?.CITY,
        farmName: farmLabor.FarmJobProject?.Farm?.NAME
    }));

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    return (
        <>
            <Box display='flex' justifyContent='center'>
                <TextField
                    label="SEARCH PROJECT"
                    color="primary"
                    size="small"
                    variant="standard" onChange={handleSearchChange} />
            </Box>

            <Box marginTop='5%' display='flex' justifyContent='center' flexDirection={'column'}>
                {isLoading && <Stack sx={{ width: '50%', color: 'grey.500', position: 'absolute' }} spacing={2}>
                    <LinearProgress color="success" />
                </Stack>}
                {rows.length === 0 && !isLoading ? <h2>No results found</h2> : <FarmingLaborCardList rows={rows} />}
            </Box>
        </>
    )
}

export default FarmingLabor;