import { useState } from "react";
import { Box, LinearProgress, Menu, MenuItem, Stack, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store";
import { selectAllAvailableICProjects } from "../../selectors/icprojects";
import { ICProject, fetchAllAvailableICProjects } from "../../redux/projects";
import { useEffect } from "react";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../utils/authentication";
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, Pagination } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from "react-router-dom";

interface RowData {
    id: number;
    project: string;
    reviewCycle: string;
    description: string;
    startDate: Date;
    endDate: Date;
    earningType: string;
    earningValue: number;
    img: string;
}

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

interface ProjectTableCardListProps {
    rows: RowData[];
}

const ProjectTableCardList: React.FC<ProjectTableCardListProps> = ({ rows }) => {
    const [expandedId, setExpandedId] = React.useState<number | null>(null);
    const [page, setPage] = React.useState(1);
    const navigate = useNavigate();
    const itemsPerPage = 9;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleExpandClick = (id: number) => {
        setExpandedId(id === expandedId ? null : id);
    };

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, row: RowData) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };


    const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
        setPage(newPage);
    };

    const paginatedRows = rows.slice((page - 1) * itemsPerPage, page * itemsPerPage);


    return (
        <React.Fragment>
            <Grid container spacing={2}>
                {paginatedRows.map((row) => (
                    <Grid item key={row.id} xs={12} sm={6} md={4}>
                        <Card sx={{ marginBottom: '20px' }}>
                            <CardHeader
                                title={row.project}
                                subheader={`Start Date ${row.startDate} End Date ${row.endDate}`}
                                action={
                                    <>
                                        <IconButton aria-label="settings" onClick={(e) => handleMenuOpen(e, row)}>
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            id="action-menu"
                                            anchorEl={anchorEl}
                                            open={Boolean(anchorEl)}
                                            onClose={handleMenuClose}
                                        >
                                            <MenuItem onClick={() => { navigate('/login'); handleMenuClose() }}>Apply this project</MenuItem>
                                        </Menu>
                                    </>
                                }
                            />
                            <CardMedia
                                component="img"
                                height="194"
                                image={row.img}
                                alt={row.project}
                                sx={{ objectFit: 'contain' }}
                            />
                            <CardContent>
                                <Typography variant="body2" color="text.secondary">
                                    Earning: {row.earningValue}, {row.earningType}, {row.reviewCycle} plan
                                    cycle
                                </Typography>
                            </CardContent>
                            <CardActions disableSpacing>
                                <ExpandMore
                                    onClick={() => handleExpandClick(row.id)}
                                    aria-expanded={expandedId === row.id}
                                    aria-label="show more"
                                    expand={expandedId === row.id}
                                >
                                    <ExpandMoreIcon />
                                </ExpandMore>
                            </CardActions>
                            <Collapse in={expandedId === row.id} timeout="auto">
                                <CardContent>
                                    <Typography paragraph>Earning: {row.earningValue}</Typography>
                                    <Typography paragraph>
                                        {row.project} is between {String(row.startDate)} and {String(row.endDate)}.
                                        It follows a {row.reviewCycle} plan
                                        cycle.
                                    </Typography>
                                    <Typography>
                                        The image shows: {row.project}
                                    </Typography>
                                </CardContent>
                            </Collapse>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <Pagination
                count={Math.ceil(rows.length / itemsPerPage)}
                page={page}
                onChange={handleChangePage}
                color="primary"
                style={{ marginTop: '20px', justifyContent: 'center', display: 'flex' }}
            />
        </React.Fragment>
    );
};

function StoreShelfStocking() {
    const [searchTerm, setSearchTerm] = useState('');

    const dispatch = useDispatch<AppDispatch>();
    const allAvailableICProjects: ICProject[] = useSelector(selectAllAvailableICProjects);
    const [allAvailableProjects, setAllAvailableProjects] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        dispatch(fetchAllAvailableICProjects());
    }, [dispatch]);

    useEffect(() => {
        setIsLoading(true);
        const allActiveProjects = allAvailableICProjects.map(async project => {
            const productImagePaths = project.Product ? JSON.parse(project.Product.IMAGEPATHS) : [];
            const urls: string[] = [];
            if (productImagePaths.length) {
                await Promise.all(
                    productImagePaths.map(async (productImagePath: string | undefined) => {
                        const imageRef = ref(storage, productImagePath);
                        await getDownloadURL(imageRef).then((url) => {
                            urls.push(url);
                        });
                    })
                );
            }
            return { ...project, imageUrls: urls };
        });

        Promise.all(allActiveProjects).then(projects => {
            setAllAvailableProjects(projects);
            setIsLoading(false);
        });

    }, [allAvailableICProjects]);

    const rows = allAvailableProjects.filter((project) => {
        return project?.NAME?.toLowerCase().includes(searchTerm)
    }).map((icproject) => ({
        id: icproject.ID,
        project: icproject.NAME,
        description: icproject.DESCRIPTION,
        reviewCycle: icproject.REVIEWCYCLE,
        startDate: icproject.STARTDATE,
        endDate: icproject.ENDDATE,
        earningType: icproject.EARNINGTYPE,
        earningValue: icproject.EARNINGVALUE,
        product: icproject.Product,
        img: icproject.imageUrls.length ? icproject.imageUrls[0] : 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
    }));

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    return (
        <>
            <Box display='flex' justifyContent='center'>
                <TextField
                    label="SEARCH PROJECT"
                    color="primary"
                    size="small"
                    variant="standard" onChange={handleSearchChange} />
            </Box>

            <Box marginTop='5%' display='flex' justifyContent='center' flexDirection={'column'}>
                {isLoading && <Stack sx={{ width: '50%', color: 'grey.500', position: 'absolute' }} spacing={2}>
                    <LinearProgress color="success" />
                </Stack>}
                {rows.length === 0 && !isLoading ? <h2>No results found</h2> : <ProjectTableCardList rows={rows} />}
            </Box>
        </>
    )
}

export default StoreShelfStocking;